const UserMain = {
  container: {
    display: 'flex'
  },
  rowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderWidth: 3,
    borderColor: 'red'
  },
  item: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  }
};

export default UserMain;
