import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Cancel from '@material-ui/icons/Cancel';
import { createHashHistory } from 'history';

const history = createHashHistory();

const cardActionStyle = {
  zIndex: 2,
  float: 'right',
};

const handleClick = () => {
  history.goBack();
};

const CancelButton = () => (
  <CardActions style={cardActionStyle} >
    <Button
      onClick={handleClick}
      style={{
        backgroundColor: '#f44336',
        color: '#fff',
        padding: '5px 8px',
        border: '1px solid #f44336',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
      }}>
      <Cancel style={{
        width: 20,
        height: 20,
        color: '#f44336',
        backgroundColor: '#fff',
        borderRadius: '30px',
        marginRight: '5px' }}
      />
      {'Cancel'}
    </Button>
  </CardActions>
);

export default CancelButton;
