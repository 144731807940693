import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination
} from 'react-admin';

export class AddEditorUsers extends Component {
  constructor () {
    super();
    this.state = {
      usersList:[],
    };
  }
  async componentDidMount () {
  }
  render () {
    return (
      <List {...this.props} exporter={false} bulkActionButtons={false} pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}>
        <Datagrid rowClick='expand' >
          <TextField source='srNo' label='Sr No.' sortable={false} />
          <TextField source='nicename' label='Name' sortable={false} />
          <TextField source='surName' label='Surname' />
          <TextField source='email' label='Email Id' />
          <TextField source='role' label='Role' />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}

export default AddEditorUsers;
