/* eslint-disable indent */
import axios from 'axios';
import _ from 'lodash';

export const getUserTransactions = async (userID) => {
 const url = `${process.env.REACT_APP_API_BASE_URL}/payment?action=fetchTransaction&users_id=${userID}`;
 const token = localStorage.getItem('token');
 return axios
  .get(url, {
   headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    cert: 'admin'
   }
  })
  .then(response => {
   if (_.has(response, ['data'])) {
    return response.data.data;
   } else {
    return {};
   }
  })
  .catch(err => {
   console.log(err.message);
  });
};

export const userRefund = (values) => {
  let action = '';
  if (values[3] === 'stripe') {
    action = 'stripeRefund';
    } else {
      action = 'satispayRefund';
    }
  const data = {
    'action': action,
    transaction_id:values[1],
      };
  return axios({
    url:`${process.env.REACT_APP_API_BASE_URL}/refunds`,
    headers: { 'authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
      'cert': 'admin' },
    method:'post',
    data,
  }).then(response => {
    if (_.has(response, 'data')) {
      return response.data;
    } else {
      return '';
    }
  }).catch((err) => {
    console.log(err.message);
  });
};
