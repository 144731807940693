import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

class CreateWidget extends Component {
  render () {
    return <WidgetForm {...this.props} />;
  }
}

export default CreateWidget;


const validateWidgetCreation = (values) => {
  const errors = {};
  if (!values.widget_name) {
    errors.passwordText = 'Widget Name is required';
  }
  if (!values.host_name) {
    errors.surName = 'Host Name is required';
  }
  
  return errors;
};
const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};
const WidgetForm = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm validate={validateWidgetCreation}>
      <TextInput label='Widget Name' source='widget_name' formClassName={classes.inlineBlock} required />
      <TextInput label='Host Name' source='host_name' formClassName={classes.inlineBlock} required />
      <BooleanInput label='Toggle Name' source='toggle_name' defaultValue={true}/>
      <BooleanInput label='Toggle Surname' source='toggle_surname' defaultValue={true}/>
      <BooleanInput label='Toggle Coupon' source='toggle_coupon' defaultValue={true}/>
      <BooleanInput label='Status' source='status' defaultValue={true} />
    </SimpleForm>
  </Create>
));
