import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, Button } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import './corporate.css';

const styles = {
  block: { display: 'block', marginRight: '1rem', width: '100%' },
  inlineBlock: { display: 'inline-block', width: '50%' },
  flexBlock: { display: 'flex', width: '100%' },
  contactContainer: { marginBottom: '1rem' },
  none: { display: 'none' }
};

class CreateCorporateClient extends Component {
  state = {
    name: '',
    metadata: [{ fullname: '', role: '', email: '', phone: '' }],
    status: '0',
    metaInfo: ['fullname', 'role', 'email', 'phone'],
    message: ''
  };

  handleChange = (index, e) => {
    const { name, value } = e.target;
    const [, idx, propName] = name.match(/metadata\[(\d+)\]\.(.+)/);
    const updatedMetadata = [...this.state.metadata];
    const isDuplicate = updatedMetadata.some((contact, i) => i !== parseInt(idx) && contact[propName] === value);
    if (isDuplicate) {
      this.setState({ message: 'Duplicate user found' })
      return;
    } else {
      this.setState({ message: '' })
    }

    if (propName === 'phone') {
      const isValidPhone = /^\d{10}$/.test(value);
      if (!isValidPhone) {
        this.setState({ message: 'Invalid phone number' })
        return;
      } else {
        this.setState({ message: '' })
      }
    }

    if (propName === 'email') {
      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      if (!isValidEmail) {
        this.setState({ message: 'Invalid email address' })
        return;
      } else {
        this.setState({ message: '' })
      }
    }
    updatedMetadata[idx][propName] = value;
    this.setState({ metadata: updatedMetadata });
  };
  handleData = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleAddContact = () => {
    this.setState(prevState => ({
      metadata: [...prevState.metadata, { fullname: '', role: '', email: '', phone: '' }]
    }));
  };

  success = () => {
    this.props.history.push('/corporate-edition');
  }

  render() {
    const { classes, ...props } = this.props;
    const { metadata, message } = this.state;
    const isFormFilled = Object.keys(metadata[0]).every(key => metadata[0][key] !== '');

    return (
      <Create {...props} onSuccess={this.success}>
        <SimpleForm formClassName={classes.inlineBlock} className='s-form' >
          <h3 className="custom-text">Company</h3>
          <TextInput label='Corporate client name' source={`name`} formClassName={classes.block} className="custom-text-data" onChange={(e) => this.handleData(e)} />
          {metadata.map((contact, index) => (
            <>
              <div key={index} className={'contactContainer'}>
                <h3 formClassName={classes.block} className="custom-text">Contact</h3>
                <TextInput label='Full name' source={`metadata[${index}].fullname`} formClassName={classes.inlineBlock} className='input-fields' value={contact.full_name} onChange={(e) => this.handleChange(index, e)} />
                <TextInput label='Role at company' source={`metadata[${index}].role`} options={{ multiLine: true }} className='input-fields' formClassName={classes.inlineBlock} value={contact.role} onChange={(e) => this.handleChange(index, e)} />
                <TextInput label='Email' source={`metadata[${index}].email`} formClassName={classes.inlineBlock} className='input-fields' value={contact.email} onChange={(e) => this.handleChange(index, e)} />
                <TextInput label='Contact number' source={`metadata[${index}].phone`} options={{ multiLine: true }} className='input-fields' formClassName={classes.inlineBlock} value={contact.contact_no} onChange={(e) => this.handleChange(index, e)} />
              </div>
            </>
          ))}
          {message.length !== 0 && <div className='error-div' style={{ width: '92%' }}>{message}</div>}
          <Button label="Add other contact" disabled={!isFormFilled || message.length !== 0} onClick={this.handleAddContact} className={(!isFormFilled || message.length !== 0) ? 'custom-btn' : 'enable-btn'} />
        </SimpleForm>
      </Create>
    );
  }
}

export default withStyles(styles)(CreateCorporateClient);
