import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

class CreateEditorUser extends Component {
  render () {
    return <UserForm {...this.props} />;
  }
}

export default CreateEditorUser;
const role = process.env.REACT_APP_EDITOR_USER_ROLE;
const type = process.env.REACT_APP_EDITOR_USER_TYPE;
// eslint-disable-next-line no-eval
const roleChoices = eval(role);
const roleArray = [];
roleChoices.forEach((elem, i) => {
  const obj = {
    id:i,
    name:elem
  };
  roleArray.push(obj);
});
// eslint-disable-next-line no-eval
const typeChoices = eval(type);
const typeArray = [];
typeChoices.forEach((elem, i) => {
  const obj = {
    id:i,
    name:elem
  };
  typeArray.push(obj);
});

const validateUserCreation = (values) => {
  const errors = {};
  if (!values.nicename) {
    errors.nicename = 'Name is required';
  }
  if (!values.passwordText) {
    errors.passwordText = 'Password is required';
  }
  if (!values.surName) {
    errors.surName = 'Surname is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.role) {
    errors.role = 'Role is required';
  }
  if (!values.type) {
    errors.type = 'Type is required';
  }

  return errors;
};

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  block: { display: 'block' },
  displayLinebreakAndTab: { whiteSpace: 'pre-wrap' },
};
const UserForm = withStyles(styles)(({ classes, ...props }) => (

  <Create {...props}>
    <SimpleForm validate={validateUserCreation} >
      <TextInput label='Name' source='nicename' formClassName={classes.inlineBlock} required />
      <TextInput label='Surname' source='surName' formClassName={classes.inlineBlock} required />
      <TextInput label='Email' source='email' formClassName={classes.inlineBlock} required />
      <TextInput label='Password' source='passwordText' formClassName={classes.inlineBlock} required />
      <SelectInput label='Role' source='role' choices={roleArray || []} optionValue='name' optionText='name' formClassName={classes.inlineBlock} required />
      <SelectInput label='Type' source='type' choices={typeArray || []} optionValue='name' optionText='name' formClassName={classes.inlineBlock} required />
    </SimpleForm>
  </Create>
));
