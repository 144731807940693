import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  ReferenceField,
  DateField,
} from 'react-admin';
import Filter from './GiftsFilter';

const CustomEmpty = () => <div>No results found</div>;

function Gift (props, i) {
  return (

    <List
      title='Gift'
      {...props}
      filters={<Filter />}
      empty={<CustomEmpty />}
      bulkActionButtons={false}
      pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
    >
      <Datagrid key={i} rowClick='expand'>
        <TextField source='id' />
        <TextField source='giftId' label='Gift Id' />
        <TextField
          source='gift'
          label='Gift'
          sortable={false}
          reference='gift'
        />

        <ReferenceField
          source='giftSenderUserId'
          reference='users'
          label='Sender Name'
        >
          <TextField source='nicename' label='Sender Name' />
        </ReferenceField>
        <TextField source='senderEmail' label='Sender Email' />
        <TextField
          source='receiverName'
          label='Receiver Name'
          sortable={false}
        />
        <TextField
          source='receiverEmail'
          label='Receiver Email'
          sortable={false}
          reference='gift'
        />
        <TextField
          source='giftSubscriptionPeriod'
          label='Gift subscription period'
          sortable={false}
          reference='gift'
        />
        <TextField
          source='redemptionStatus'
          label='Redemption status'
          sortable={false}
          reference='gift'
        />
        <DateField source='giftExpiryDate' showTime />
        <EditButton label={'Edit'} />
      </Datagrid>
    </List>
  );
}

export default Gift;
