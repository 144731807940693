import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../Styles/LoginPage';

const CustomLoginForm = withStyles({
  button: { background: '#21666e' },
})(LoginForm);

const CustomLoginPage = props => (
  <Login
    loginForm={<CustomLoginForm />}
    {...props}
  />
);

export default withStyles(styles)(CustomLoginPage);
