import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

export const getOrganisationUser = async params => {
  const businessUrl = `${process.env.REACT_APP_API_BASE_URL}/organisation-user?organisation_id=${params.id}&$limit=${params.limit}&$skip=${params.skip}`;
  const token = await localStorage.getItem('token');
  return axios({
    method: 'get',
    url: businessUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  })
    .then(response => {
      const businessUsers = {
        total: response.data.total,
        skip: response.data.skip,
        limit: response.data.limit,
        data: []
      };
      if (response.data.data.length > 0) {
        _.map(response.data.data, item => {
          let data = {
            user_id: item.user_id,
            name: `${item.nicename} ${item.surName}`,
            email: item.email,
            added_on: moment(item.createdAt).format('YYYY-MM-DD H:mm:ss'),
            role:
       _.has(item, 'role_name') && item.role_name === 'business_admin'
         ? 'Admin'
         : 'Member',
            status: item.status === 1 ? 'Active' : 'Inactive'
          };
          businessUsers.data.push(data);
        });
      }
      return businessUsers;
    })
    .catch({});
};

export const getCredits = async params => {
  const creditUrl = `${process.env.REACT_APP_API_BASE_URL}/business-charge?org_id=${params.id}&$limit=${params.limit}&$skip=${params.skip}&$sort[createdAt]=-1`;
  const token = await localStorage.getItem('token');
  return axios({
    method: 'get',
    url: creditUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  })
    .then(response => {
      const creditData = {
        org_id: params.id,
        total: response.data.total,
        skip: response.data.skip,
        limit: response.data.limit,
        data: []
      };
      if (response.data.data.length > 0) {
        _.map(response.data.data, item => {
          try {
            let data = {
              plan: item.amount,
              amount: item.amount,
              available_credits: item.available_amount,
              plan_id: item.plan_id,
              created_at: moment(item.createdAt).format('YYYY-MM-DD H:mm:ss'),
              action: item.action,
            };
            if(item.action.toLowerCase() === 'charge' && item.metadata.noOfUser) {
              data.chargable_amount = `-${item.metadata.noOfUser}`;
            } else if (item.action.toLowerCase() === 'purchase' ||
             item.action.toLowerCase() === 'renew') {
              data.chargable_amount = `+${item.amount}`;
            } else if(item.action.toLowerCase() === 'addon') {
              data.chargable_amount = ''
            }


            creditData.data.push(data);
          } catch (err) {
            console.log(err);
          }
        });
      }

      return creditData;
    })
    .catch({});
};

// add credit manually ops
export function updateCredit (id, credit, params) {
  const data = {
    org_id: id,
    credit_amount: credit,
    action: 'creditAddon'
  };
  const addcreditUrl = `${process.env.REACT_APP_API_BASE_URL}/business-charge`;
  return axios({
    url: addcreditUrl,
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
      cert: 'admin'
    },
    method: 'post',
    data
  })
    .then(response => {
      console.log('response', response);
      if (_.has(response, 'data')) {
        return response.data;
      } else {
        return '';
      }
    })
    .catch(error => {
      console.log('[ERROR][ADDCREDIT] ===================', error);
    });
}
