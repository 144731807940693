import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton
} from 'react-admin';
function Corporate (props, i) {
  return (
    <List title='Corporate' {...props} bulkActionButtons={false} pagination={false}>
      <Datagrid key={i} rowClick='expand' >
        <TextField source='sr_no' label='Sr No.' sortable={false} reference='corporate' />
        <TextField source='name' label='Name' sortable={false} reference='corporate' />
        <TextField source='version' label='Version' reference='corporate' />
        <TextField source='edition' label='Edition' reference='corporate' />
        <TextField source='language' label='Language' sortable={false} reference='corporate' />
        <EditButton label={'Edit'} />
      </Datagrid>
    </List>
  );
};

export default Corporate;
