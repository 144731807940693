import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getOrganisationUser } from '../../DataProvider/Business';

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  rowBg: {
    backgroundColor: '#F4FCF4'
  },
  decoration: {
    textDecoration: 'none'
  }
});

class BusinessUser extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      limit: 10,
      skip: 0,
      total: 0
    };
  }

  async componentWillMount () {
    const params = {
      limit: this.state.limit,
      skip: this.state.skip,
      id: this.props.id
    };
    const businessUsers = await getOrganisationUser(params);
    this.setState({
      rows: businessUsers.data,
      total: businessUsers.total,
      skip: businessUsers.skip,
      limit: businessUsers.limit
    });
  }

 handleChangePage = async (event, page) => {
   const params = {
     limit: this.state.limit,
     skip: this.state.rowsPerPage * page,
     id: this.props.id
   };
   const businessUsers = await getOrganisationUser(params);
   this.setState({
     rows: businessUsers.data,
     total: businessUsers.total,
     skip: businessUsers.skip,
     limit: businessUsers.limit,
     page: page
   });
 };

 handleChangeRowsPerPage = async event => {
   const params = {
     limit: event.target.value,
     skip: 0,
     id: this.props.id
   };
   const businessUsers = await getOrganisationUser(params);
   await this.setState({
     rows: businessUsers.data,
     total: businessUsers.total,
     rowsPerPage: event.target.value,
     skip: businessUsers.skip,
     limit: businessUsers.limit,
     page: 0
   });
 };

 render () {
   const { classes } = this.props;
   const { rows, rowsPerPage, page, total } = this.state;
   return (
     <Paper className={classes.root}>
       <div className={classes.tableWrapper}>
         <Table className={classes.table}>
           <TableHead>
             <TableRow>
               <TableCell>User Id</TableCell>
               <TableCell align='left'>Name</TableCell>
               <TableCell align='left'>Email</TableCell>
               <TableCell align='left'>Added On</TableCell>
               <TableCell align='left'>Role</TableCell>
               <TableCell align='left'>Status</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows.length > 0 &&
        rows.map((row, key) => (
          <TableRow
            key={key}
            className={row.role === 'Admin' ? classes.rowBg : ''}
          >
            <TableCell align='left'>{row.user_id}</TableCell>
            <TableCell align='left'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`#users/${row.user_id}`}
                className={classes.decoration}
              >
                {row.name}
              </a>
            </TableCell>
            <TableCell align='left'>{row.email}</TableCell>
            <TableCell align='left'>{row.added_on}</TableCell>
            <TableCell align='left'>{row.role}</TableCell>
            <TableCell align='left'>{row.status}</TableCell>
          </TableRow>
        ))}
           </TableBody>
           {rows.length > 0 && (
             <TableFooter>
               <TableRow>
                 <TablePagination
                   rowsPerPageOptions={[5, 10, 25]}
                   count={total}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   backIconButtonProps={{
                     'aria-label': 'Prev'
                   }}
                   nextIconButtonProps={{
                     'aria-label': 'Next'
                   }}
                   onChangePage={this.handleChangePage}
                   onChangeRowsPerPage={this.handleChangeRowsPerPage}
                 />
               </TableRow>
             </TableFooter>
           )}
         </Table>
       </div>
     </Paper>
   );
 }
}

BusinessUser.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string
};

export default withStyles(styles)(BusinessUser);
