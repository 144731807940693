import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { SimpleForm, TextInput, SelectInput, required, minLength, maxLength, Create, DateInput } from 'react-admin';

class CreatePromotionCoupon extends Component {
  render () {
    return <CreatePromotionForm {...this.props} />;
  }
}

export default CreatePromotionCoupon;

const validateCode = [required(), minLength(2), maxLength(30)];
const validateCouponCreation = (values) => {
  const errors = {};
  const expiryDate = new Date(values.expiry_date);
  if (!values.expiry_date) {
    errors.expiry_date = 'Expiry date is required';
  }
  if (values.expiry_date && expiryDate <= new Date()) {
    errors.expiry_date = 'Expiry date should be greater';
  }
  return errors;
};

const validateDescription = [required(), minLength(4), maxLength(100)];
const validateValidity = [required()];
const validateMaxUsage = [required()];
const customChoices = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 23, name: '23' },
  { id: 24, name: '24' }
];

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const CreatePromotionForm = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm validate={validateCouponCreation}>
      <TextInput label='Code' source='code' formClassName={classes.inlineBlock} defaultValue='PR-' validate={required('Do not remove prefix PR-')} />
      <TextInput label='Promotion Campaign' source='pr_campaign' formClassName={classes.inlineBlock} validate={validateCode} />
      <TextInput label='Description' source='description' options={{ multiLine: true }} formClassName={classes.inlineBlock} validate={validateDescription} />
      <SelectInput source='duration' label='Duration' choices={customChoices} validate={validateValidity} formClassName={classes.inlineBlock} optionValue='id' optionText='name' />
      <DateInput label='Expiry Date' source='expiry_date' formClassName={classes.inlineBlock} />
      <TextInput source='max_user' label='Max Number Of Usage' validate={validateMaxUsage} formClassName={classes.inlineBlock} type='number' />
    </SimpleForm>
  </Create>
));
