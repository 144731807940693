import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const Exporter = users => {
  const usersForExport = users.map(user => {
    const {
      email,
      nicename,
      subscription_expiration_date_formatted, // eslint-disable-line
      subscription_type, // eslint-disable-line
      mailChimpStatus, // eslint-disable-line
      os_type, // eslint-disable-line
      autoRenewStatus,
      last_signed_in // eslint-disable-line
    } = user;
    const userForExport = {};
    userForExport.Email = email;
    userForExport.Name = nicename;
    userForExport.Subscription_Expiry = subscription_expiration_date_formatted; // eslint-disable-line
    userForExport.Subscription_Type = subscription_type; // eslint-disable-line
    userForExport.Last_Login = last_signed_in; // eslint-disable-line
    userForExport.Platform = os_type; // eslint-disable-line
    userForExport.Auto_Renew = autoRenewStatus;
    userForExport.MailChimp_Status = mailChimpStatus;
    return userForExport;
  });
  const csv = convertToCSV({
    data: usersForExport,
    fields: ['Name', 'Email', 'Subscription_Expiry', 'Subscription_Type', 'Last_Login',
      'Platform', 'Auto_Renew', 'MailChimp_Status']
  });
  downloadCSV(csv, 'Users'); // download as 'Users.csv' file
};

export default Exporter;
