import React, { Component } from 'react';
import Filter from './WidgetUserFilter';
import {
  List,
  Datagrid,
  TextField,
  Pagination
} from 'react-admin';

class PromotionCode extends Component {
  render () {
    return (
      <List
        {...this.props}
        exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}>
        <Datagrid key={'12'}>
          <TextField source='srNo' label='Sr No' sortable={false} />
          <TextField source='niceName' label='Name' sortable />
          <TextField source='surname' label='Surname' sortable />
          <TextField source='email' label='Email' sortable />
          <TextField source='os_type' label='OS Type' sortable />
          <TextField source='users_id' label='Users Id' sortable />
          <TextField source='createdAt' label='CreatedAt' sortable />
          <TextField source='widget_id' label='Widget Id' sortable />
          <TextField source='host_name' label='Host Name' sortable />
        </Datagrid>
      </List>
    );
  }
}

export default PromotionCode;
