import React, { Component, Fragment } from 'react';
import {
  Tabs,
  Tab,
  Divider
} from '@material-ui/core';

import OrganisationInfo from './OrganisationInfo';
import BusinessUser from './BusinessUser';
import Credit from './Credits.js';

class BusinessUserEdit extends Component {
  constructor (props) {
    super(props);
    this.state = { value: 0 };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render () {
    const { value } = this.state;
    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={value}
          indicatorColor='primary'
          onChange={this.handleChange}
        >
          <Tab label='Edit' style={{ fontWeight: 'bold' }} />
          <Tab label='User' style={{ fontWeight: 'bold' }} />
          <Tab label='Credits' style={{ fontWeight: 'bold' }} />
        </Tabs>
        <Divider />
        { value === 0 && <OrganisationInfo organisationid={this.props.id} {...this.props} />}
        { value === 1 && <BusinessUser userId={this.props.id} {...this.props} />}
        { value === 2 && <Credit userId={this.props.id} {...this.props} />}
      </Fragment>
    );
  }
}

export default BusinessUserEdit;
