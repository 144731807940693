import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import Einvoice from './Einvoice';
import EinvoiceBusiness from './EinvoiceBusinnesTab';
class EinvoiceTab extends Component {
  constructor (props) {
    super(props);
    this.state = { value: 0 };
  }

 handleChange = (event, value) => {
   this.setState({ value });
 };

 render () {
   const { value } = this.state;
   return (
     <Fragment>
       <Tabs
         fullwidth="true"
         centered
         value={value}
         indicatorColor='primary'
         onChange={this.handleChange}
       >
         <Tab label='Regular Transactions' style={{ fontWeight: 'bold' }} />
         <Tab label='Business Transactions' style={{ fontWeight: 'bold' }} />
       </Tabs>
       <Divider />
       {value === 0 && <Einvoice {...this.props} />}
       {value === 1 && <EinvoiceBusiness {...this.props} />}
     </Fragment>
   );
 }
}

export default EinvoiceTab;
