import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required, minLength, maxLength, choices } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const validateName = [required(), minLength(2), maxLength(30)];
const validatePrefix = [required(), minLength(4), maxLength(4)];
const validateDescription = [required(), minLength(4), maxLength(100)];
const validateValidity = choices(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'], 'Must be greater or equal 1');
const customChoices = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
  { id: '12', name: '12' },
  { id: '13', name: '13' },
  { id: '14', name: '14' },
  { id: '15', name: '15' },
  { id: '16', name: '16' },
  { id: '17', name: '17' },
  { id: '18', name: '18' },
  { id: '19', name: '19' },
  { id: '20', name: '20' },
  { id: '21', name: '21' },
  { id: '22', name: '22' },
  { id: '23', name: '23' },
  { id: '24', name: '24' }
];

class CreateCoupon extends Component {
  render () {
    return <CouponForm {...this.props} />;
  }
}

export default CreateCoupon;

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const CouponForm = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label='Name' source='name' formClassName={classes.inlineBlock} validate={validateName} />
      <TextInput label='Prefix' source='prefix' formClassName={classes.inlineBlock} validate={validatePrefix} />
      <TextInput label='Description' source='short_description' options={{ multiLine: true }} formClassName={classes.inlineBlock} validate={validateDescription} />
      <SelectInput source='validity' choices={customChoices} validate={validateValidity} formClassName={classes.inlineBlock} optionValue='id' optionText='name' />
    </SimpleForm>
  </Create>
));
