import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import EditionSchedule from './EditionSchedule';
import EditionJournalist from './EditionJournalist';
import './Edition.css';
import { getCorporateEdition } from '../../DataProvider/Corporate';
import { getUsersList } from '../../DataProvider/EditionConfiguration';
import { v4 as uuidv4 } from 'uuid';
class Edition extends Component {
  constructor (props) {
    super(props);
    this.state = { value: 0,
      listOfCorporateUsers:[],
      users:[],
    };
  }

 handleChange = (event, value) => {
   this.setState({ value });
 };
 updateUsers = (usersList, rerender = true) => {
   rerender ? this.setState({ users:usersList })
   // eslint-disable-next-line
     : this.state.users = usersList;
 }
 async componentDidMount () {
   const listOfCorporate = await getCorporateEdition();
   const listOfUsers = await getUsersList();
   this.setState({ listOfCorporateUsers: listOfCorporate, users: listOfUsers.data });
 }
 render () {
   const { value, listOfCorporateUsers, users } = this.state;
   return (
     <Fragment>
       <Tabs
         fullwidth='false'
         className='tabs-container'
         centered
         value={value}
         indicatorColor='primary'
         onChange={this.handleChange}
       >
         <Tab label='Edition-week Config' style={{ fontWeight: 'bold' }} />
         <Tab label='Edition-journalist Config' style={{ fontWeight: 'bold' }} />
       </Tabs>
       <Divider />
       {value === 0 && <EditionSchedule key={listOfCorporateUsers.length} list={listOfCorporateUsers} {...this.props} />}
       {value === 1 && <EditionJournalist key={uuidv4()} list={listOfCorporateUsers} users={users} updateUserFunction={this.updateUsers} {...this.props} />}
     </Fragment>
   );
 }
}

export default Edition;
