import React from 'react';

import { List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  BooleanField,
  EditButton,
} from 'react-admin';

import Exporter from './CsvExporter';
import Filter from './UserFilter';

const UserList = (props, i) => (
  <List title='All users' {...props} filters={<Filter />} exporter={Exporter} bulkActionButtons={false}>
    <Datagrid key={i}>
      <TextField source='id' />
      <TextField label='Name' source='nicename' sortable={false} />
      <TextField label='Surname' source='surName' sortable={false} />
      <TextField source='email' sortable={false} />
      <ChipField label='Subscription Type' source='subscription_type' sortable={false} />
      <TextField label='Recipient Code' source='recipient_code' sortable={false} />
      <DateField label='Subscription Expired' source='subscription_expiration_date_formatted' sortable={false} />
      <DateField label='Last Seen' source='updatedAt' showTime sortable={false} />
      <TextField label='Platform' source='os_type' sortable={false} />
      <BooleanField label='Auto Renew' source='autoRenewStatus' sortable={false} />
      <BooleanField label='Mailchimp Status' source='mailChimpStatus' />
      <TextField label='Campaign' source='campaign' sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
