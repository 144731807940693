import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { getUserTransactions, userRefund } from '../../DataProvider/UserTransactions';

function getModalStyle () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
});

class Transaction extends Component {
  constructor () {
    super();
    this.state = {
      open: false,
      modalStyle: getModalStyle(),
      userData:[],
      selectedRow:null,
      loader:false
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount () {
    try {
      // eslint-disable-next-line react/prop-types
      let userTransactions = await getUserTransactions(this.props.id);
      this.setState({
        userData: userTransactions,
      });
    } catch (error) {
      console.log('[CorporateEdit][willmount] ======= ', error);
    }
  }

  refund = async () => {
    this.setState({
      loader:true
    });
    let userRefundResult = await userRefund(this.state.selectedRow);
    if (userRefundResult.refunded) {
      window.location.reload();
    } else if (!userRefundResult.refunded) {
      if (userRefundResult.data.msg.length) {
        alert(`${userRefundResult.data.msg}`);
      } else {
        alert('Refund unsuccessful due to technical reasons');
      }
    } else {
      this.handleClose();
    }
  }

 body = () => {
   // eslint-disable-next-line react/prop-types
   const { classes } = this.props;
   return (
     <>
       {this.state.loader ? <div style={this.state.modalStyle} className={classes.paper}><div style={{ textAlign: 'center' }}><CircularProgress /></div> </div> : <div style={this.state.modalStyle} className={classes.paper}>
         <h2 id='simple-modal-title text' style={{ textAlign: 'center' }}>
          Are you Sure?
         </h2>
         <div className='d-flex' style={{ textAlign: 'center' }}>
           <Button
             style={{ marginTop: '5px', marginRight: '10px' }}
             variant='contained'
             color='primary'
             onClick={() => this.refund()}
           >
      yes
           </Button>
           <Button
             style={{ marginTop: '5px' }}
             variant='contained'
             color='primary'
             onClick={() => this.handleClose()}
           >
      No
           </Button>
         </div>
       </div>}

     </>
   );
 };

 handleClose = () => {
   this.setState({
     open: false,
     selectedRow:null
   });
 };

 openModal = async values => {
   await this.setState({
     open: true,
     selectedRow:values
   });
 };

 ButtonComponent = (value, tableMeta, updateValue) => {
   // eslint-disable-next-line eqeqeq
   if (tableMeta.rowData[5] != 'refunded' && tableMeta.rowData[5] != 'failed' && tableMeta.rowData[3] != 'appstore') {
     return (
       <div>
         <Button
           variant='contained'
           color='primary'
           onClick={() => this.openModal(tableMeta.rowData)}
         >
      Refund
         </Button>
       </div>
     );
   }
 };
 render () {
   const columns = [
     {
       name: 'transactionDate',
       label: 'Transaction Date',
       options: {
         filter: false,
         sort: false
       }
     },
     {
       name: 'transactionId',
       label: 'Transaction Id',
       options: {
         filter: false,
         sort: false
       }
     },
     {
       name: 'paymentFor',
       label: 'Category',
       options: {
         filter: false,
         sort: false
       }
     },
     {
       name: 'paymentGateway',
       label: 'Payment Gateway',
       options: {
         filter: false,
         sort: false
       }
     },
     {
       name: 'amount',
       label: 'Amount'
     },
     {
       name: 'status',
       label: 'Status'
     }, // add new columnn before Subscription Type
     {
       name: 'action',
       label: '',
       options: {
         filter: true,
         sort: true,
         customBodyRender: this.ButtonComponent
       }
     }
   ];
   return (
     <div>
       <MUIDataTable
         title='Transactions'
         data={this.state.userData}
         columns={columns}
         options={{
           selectableRows: false
         }}
       />
       <Modal
         open={this.state.open}
         onClose={this.handleClose}
         aria-labelledby='simple-modal-title'
         aria-describedby='simple-modal-description'
       >
         <div>{this.body()}</div>
       </Modal>
     </div>
   );
 }
}

export default withStyles(styles)(Transaction);