import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

import { updateCredit } from '../../DataProvider/Business';

function getModalStyle () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal (props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [credit, addCredit] = React.useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = event => {
    event.preventDefault();
    const { value } = event.target;
    let count = value;
    addCredit(count);
  };

  const handleSubmit = async (props) => {
    const { id } = props;
    let addCredit = await updateCredit(id, credit);
    if (addCredit) {
      props.setCredit();
      handleClose();
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id='simple-modal-title'>Add the number of credits you want to add</h2>
      <form onSubmit={() => handleSubmit(props)}>
        <TextField
          autoFocus
          required
          fullWidth
          id='credit'
          type='number'
          placeholder='Add credit'
          onChange={handleOnChange}
        />
        <Button
          style={{ marginTop: '5px' }}
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
        >
              Add
        </Button>
      </form>
    </div>
  );

  return (
    <div>
      <Button
        className={props.classes.button}
        style={{ marginLeft: '5px' }}
        variant='contained'
        color='primary'
        onClick={handleOpen}
      >
           + credit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </div>
  );
}
