
import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  BooleanInput,
  SaveButton,
  Toolbar,
  DeleteButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from '../../Users/component/CancelButton';
import '../../Users/component/Styles/userStyle.css';
const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

function FormToolbar (props) {
  return (
    <Toolbar {...props} >
      <SaveButton />
      <DeleteButton undoable={false} style={{ position: 'absolute', right: '20px' }} />
    </Toolbar>
  );
};

const EditWidgetTab = withStyles(styles)(({ classes, ...props }) =>{
  return (<Edit title='Update' actions={<CancelButton />} {...props} >
    <SimpleForm toolbar={<FormToolbar />}>
    <TextInput label='Widget Name' source='widget_name' formClassName={classes.inlineBlock} disabled/>
      <TextInput label='Host Name' source='host_name' formClassName={classes.inlineBlock} disabled/>
      <BooleanInput label='Toggle Name' source='toggle_name'/>
      <BooleanInput label='Toggle Surname' source='toggle_surname' />
      <BooleanInput label='Toggle Coupon' source='toggle_coupon'/>
      <BooleanInput label='Status' source='status' />
      <TextInput label='Copy Embed Code' source='url' formClassName={classes.inlineBlock} disabled/>
    </SimpleForm>
  </Edit>)
}
);

export default EditWidgetTab;
