import React, {useState, useEffect} from 'react';
import { List, Datagrid, TextField, TopToolbar, Filter, SelectInput } from 'react-admin';

const getMonthList = (filterYear) => {
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];
  
  const currentDate = new Date();
  const currentYear = String(currentDate.getFullYear());
  const currentMonthIndex = currentDate.getMonth();

  if(filterYear && (filterYear === currentYear)) {
    const filteredMonths = months.splice(0,currentMonthIndex+1);
    for(let index = currentMonthIndex; index < 11; index++) {
      filteredMonths.push('')
    }
    return filteredMonths;
  }

  if(filterYear && (filterYear < currentYear)) {
    return months
  }

  if(!filterYear) {
    // Remove the months uptil the current month and reverse them
    const removedMonths = months.splice(0, currentMonthIndex+1).reverse();

    // Reverse the remaining months array
    const reversedMonths = months.reverse();

    // Concatenate the reversed removed months with the reversed remaining months
    const rearrangedMonths = removedMonths.concat(reversedMonths);

    return rearrangedMonths;
  }


}

const CustomActions = () => (
  <TopToolbar style={{ justifyContent: 'center', marginTop: '20px' }}>
    <YourFilter context="button" />
  </TopToolbar>
);

const YourFilter = (props) => {

  const handleHideFilter = () => {
    window.location.href = window.location.href.split('?')[0]
    window.location.reload();
  };
  return (
  <Filter {...props} hideFilter={() => handleHideFilter()}>
    <SelectInput
      label="Set Year"
      source="yearFilter" // Replace with your actual filter key
      choices={[
        { id: '2024', name: '2024' },
        { id: '2023', name: '2023' },
        { id: '2022', name: '2022' },
        { id: '2021', name: '2021' },
        { id: '2020', name: '2020' },
        { id: '2019', name: '2019' },
        { id: '2018', name: '2018' },
      ]}
      onChange={props.handleYearChange}
    />
  </Filter>
)};



const CustomCell = ({ record, source }) => {
  // Add your conditions for styling based on the source name
  const shouldApplyStyle = ['NEW USER', 'CONVERSION', 'TOTAL ACTIVE SUBSCRIPTIONS', 'EXPIRED SUBSCRIPTIONS PER MONTH', 'PAYMENTS', 'RECURRING, UPGRADE/DOWNGRADE PAYMENTS'].includes(record[source]);
  const shouldApplyMargin = ['CONVERSION', 'TOTAL ACTIVE SUBSCRIPTIONS', 'EXPIRED SUBSCRIPTIONS PER MONTH', 'PAYMENTS', 'RECURRING, UPGRADE/DOWNGRADE PAYMENTS'].includes(record[source]);
  const shouldApplySubSectionStyles = ['Month Converted From Trial', 'Year Converted From Trial', 'Lifetime Converted From Trial',
   'Total Month Active Subscription', 'Total Year Active Subscription', 'Total Lifetime Active Subscription', 'Total', 'Month Expired',
    'Year Expired', 'Trial Expired', 'Stripe', 'Satispay', 'Appstore', 'Monthly', 'Yearly', 'Lifetime', 'Total Recurring Payments'].includes(record[source]);
    const labelStyle = shouldApplyStyle ? {
      display: 'inline-block',
      width: '345px',
      textAlign: 'left',
      fontWeight: '800'
  } : shouldApplySubSectionStyles ? {
      display: 'inline-block',
      margin: '10px 0 10px 0',
      fontWeight: '600'
  } : {
      fontWeight: '400',
      textAlign: 'center'
  };
  const textStyle = {
    color: '#737373',
    textAlign: 'center',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
   }
    
  const margin = shouldApplyMargin ? {
    marginTop: '32px'
  } : {};

  const mergedStyle = { ...textStyle, ...margin, ...labelStyle};

  return (
    <span style={mergedStyle}>
      {record[source]}
    </span>
  );
};


const UserStatList = (props) => {
  const [monthNames, setMonthNames] = useState([]);

  useEffect(() => {
    const url = window.location.href;

    // Extracting the query string from the URL
    const queryString = url.split('?')[1];

    // Parsing the query string to extract the parameters
    const params = new URLSearchParams(queryString);

    // Decoding the displayedFilters parameter
    const FiltersParam = decodeURIComponent(params.get('filter'));

    // Parsing the JSON string to get the object
    const FilteredParams = JSON.parse(FiltersParam);

    if(FilteredParams) {
      setMonthNames(getMonthList(FilteredParams.yearFilter));
    } else {
      setMonthNames(getMonthList(false));
    }

  },[])

  const handleYearChange = (event) => {
    setMonthNames(getMonthList(event.target.value));
  };

  const monthColor = {
    color: '#737373'
  }

  return (
    <List {...props} basePath="/user-stats" resource="user-stats" filters={<YourFilter handleYearChange={handleYearChange} />} actions={<CustomActions />} bulkActionButtons={false} pagination={false}>
      {monthNames && <Datagrid>
        <CustomCell source="query" label="" />
        {monthNames.map(month => <TextField key={month} source={month} label={month} style={monthColor} sortable={false} />)}
      </Datagrid> }
    </List>
  );
};

export default UserStatList;