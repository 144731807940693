import axios from 'axios';
import _ from 'lodash';
// import download from 'downloadjs';

export const getWidgetUsers = async (params = {}) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/widget`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then(response => {
    if (_.has(response, ['data', 'data'])) {
      return response.data.data;
    } else {
      return [];
    }
  }).catch(() => {
  });
};
