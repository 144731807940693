import React from 'react';
import {
 TextInput,
 Edit,
 SimpleForm,
 SaveButton,
 Toolbar,
 SelectInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import CancelButton from './CancelButton';
import { provinceOptions } from '../../../lib/config';

const styles = {
 inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

const FormToolbar = props => (
 <Toolbar {...props}>
  <SaveButton />
 </Toolbar>
);

const UserBusinessTab = withStyles(styles)(({ classes, ...props }) => (
 <Edit title="Update" actions={<CancelButton />} {...props}>
  <SimpleForm toolbar={<FormToolbar />}>
   <TextInput
    source="business_address"
    formClassName={classes.inlineBlock}
    multiline
   />
   <TextInput source="business_city" formClassName={classes.inlineBlock} />
   <TextInput source="business_country" formClassName={classes.inlineBlock} />
   <TextInput source="business_phone" formClassName={classes.inlineBlock} />
   <TextInput source="business_piva" formClassName={classes.inlineBlock} />
   <TextInput source="postal_code" formClassName={classes.inlineBlock} />
   <SelectInput
    source="province"
    choices={provinceOptions}
    formClassName={classes.inlineBlock}
    optionValue="id"
    optionText="name"
   />
   <TextInput source="recipient_code" formClassName={classes.inlineBlock} />
  </SimpleForm>
 </Edit>
));

export default UserBusinessTab;
