import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  BooleanInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from '../../Users/component/CancelButton';
import '../../Users/component/Styles/userStyle.css';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

function FormToolbar (props) {
  const {record} = props;
  const {gift_status } = record;
  let updatedProps = props ;
  updatedProps.record.gift_status = gift_status === '0' ? false : true; 

  
  return (
    <Toolbar {...updatedProps}>
      <SaveButton />
    </Toolbar>
  );
}

const GiftsEdit = withStyles(styles)(({ classes, ...updatedProps }) => (
  <Edit title='Update' actions={<CancelButton />} {...updatedProps}>
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput
        source='gift_unique_key'
        formClassName={classes.inlineBlock}
        style={{ fontStyle: 'italic' }}
      />

      <TextInput
        source='gift_reciver_nick_name'
        formClassName={classes.inlineBlock}
        readonly
        disabled
      />
      <TextInput
        label='Gift Id'
        source='gift_id'
        formClassName={classes.inlineBlock}
        readonly
        disabled
      />
      <TextInput
        label='Gift Message'
        source='gift_message'
        formClassName={classes.inlineBlock}
      />
      <BooleanInput
        label='Redemption Status'
        source='gift_status'
        formClassName={classes.inlineBlock}
      />
    </SimpleForm>
  </Edit>
));

export default GiftsEdit;

