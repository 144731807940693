import React, { Component } from 'react';
import { Filter, TextInput } from 'react-admin';

class CouponFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return (
      <Filter {...this.props}>
        <TextInput label='Search' source='q' alwaysOn />
      </Filter>
    );
  }
}

export default CouponFilter;
