import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { getAllCouponList } from '../../DataProvider/Coupon';
import GetAppIcon from '@material-ui/icons/GetApp';
const defaultToolbarStyles = {
  iconButton: {}
};

class CustomToolbar extends React.Component {
  handleClick = () => {
    const { toggleLoadingState } = this.props;

    toggleLoadingState(true);
    getAllCouponList(this.props.id, toggleLoadingState);
  };

  render () {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={'Download'}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <GetAppIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  CustomToolbar
);
