import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, DateInput, required } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
class PromotionEdit extends Component {

  render () {
    return <PromotionForm {...this.props} />;
  }
}

export default PromotionEdit;

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const validateCouponCreation = (values) => {
  const errors = {};
  if (!values.expiry_date) {
    errors.expiry_date = 'Expiry date is required';
  }
  if (values.expiry_date && moment(values.expiry_date) <= moment().toDate()) {
    errors.expiry_date = 'Expiry date should be greater';
  }
  return errors;
};

const PromotionForm = withStyles(styles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm validate={validateCouponCreation}>
      <TextInput label='Code' source='code' formClassName={classes.inlineBlock} validate={required('Do not remove prefix PR-')} />
      <TextInput label='PR Campaign' source='pr_campaign' formClassName={classes.inlineBlock} />
      <TextInput label='Description' source='description' formClassName={classes.inlineBlock} />
      <TextInput label='Duration' source='duration' formClassName={classes.inlineBlock} />
      <DateInput label='Expiry Date' source='expiry_date' formClassName={classes.inlineBlock} />
      <TextInput label='max_user' source='max_user' />
    </SimpleForm>
  </Edit>
));
