import React, { Component } from 'react';
import Filter from './PromotionFilter';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination
} from 'react-admin';
import ColumnHeaderWithTooltip from './CustomTooltip';

class PromotionCode extends Component {
  render () {
    return (
      <List
        {...this.props}
        exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}>
        <Datagrid key={'12'}>
          <TextField source='srNo' label='Id' sortable={false} />
          <TextField source='code' label='Code' sortable />
          <TextField source='description' label='Description' />
          <TextField source='duration' label='Duration' sortable />
          <TextField source='expiry_date' label='Expiry Date' />
          <TextField source='redemeed' label='Redemeed' />
          <TextField source='userPurchasingSubscription' label={
            <ColumnHeaderWithTooltip label='Conversion' description=' (It will display the number of users converted from promotion or who made any purchase)' />
          } />
          <TextField source='expiredUsers' label={
            <ColumnHeaderWithTooltip label='Expired' description='(It will display the number of expired promotion users who never made any purchase)' />
          } />
          <TextField source='max_user' label={
            <ColumnHeaderWithTooltip label='No of Usage' description='Maximum limit of using promotion code' />
          } sortable />
          <TextField source='status' label='Status' sortable />
          <TextField source='pr_campaign' label='PR Campaign' sortable />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}

export default PromotionCode;
