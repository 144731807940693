import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import EditEditorUserTab from './EditEditorUserTab';
export class EditEditorUser extends Component {
  constructor (props) {
    super(props);
    this.state = { value: 0 };
  }

    handleChange = (event, value) => {
      this.setState({ value });
    };
    render () {
      const { value } = this.state;
      return (
        <Fragment>
          <Tabs
            fullWidth
            centered
            value={value}
            indicatorColor='primary'
            onChange={this.handleChange}
          >
            <Tab label='Update User Info' style={{ fontWeight: 'bold' }} />
          </Tabs>
          <Divider />
          {value === 0 && <EditEditorUserTab {...this.props} />}
        </Fragment>
      );
    }
}

export default EditEditorUser;
