import React, { Component, Fragment } from 'react';
import { Responsive, Title } from 'react-admin';
import axios from 'axios';

import Users from './Users';
import ConversionRate from '../component/ConversionRate';

class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {},
      conversionRate: []
    };
  }

  async componentWillMount () {
    let data = {};
    const url = `${process.env.REACT_APP_API_BASE_URL}/dashboard`;
    const token = await localStorage.getItem('token');
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        cert: 'admin'
      }
    }).then((response) => {
      if (typeof response.data !== 'undefined') {
        const resp = response.data;
        data.users = [
          { label: 'Total user', value: resp.user.total },
          { label: 'Trial user', value: resp.user.trial },
          { label: 'Subscribed user', value: resp.user.subscribed },
          { label: 'Expired user', value: resp.user.expired },
          { label: 'Promotion user', value: resp.user.promotion },
        ];
        data.subscribe = [
          { label: 'Monthly Subscriber', value: resp.subscription.monthly_subscribed },
          { label: 'Yearly Subscriber', value: resp.subscription.yearly_subscribed },
          { label: 'Lifetime Subscriber', value: resp.subscription.lifetime_subscribed },
          { label: 'Corporate Subscriber', value: resp.subscription.corporate_subscribed },
          { label: 'Partner Subscriber', value: resp.subscription.partner_subscribed },
          { label: 'Canceled Subscriber', value: resp.subscription.canceled_subscribed },
        ];
        data.business = [
          { label: 'Organisation', value: resp.business.org },
          { label: 'Active Organisation', value: resp.business.active_org },
          { label: 'User', value: resp.business.user },
          { label: 'Active User', value: resp.business.active_user },
          { label: 'News Letter Sent', value: resp.business.news_letter_sent }
        ];

        this.setState({ data:  data, conversionRate: response.data.conversionRate });
      }
    }).catch(() => {
      data.users = [
        { label: 'Total user', value: '' },
        { label: 'Trial user', value: '' },
        { label: 'Subscribed user', value: '' },
        { label: 'Expired user', value: '' },
      ];
      data.subscribe = [
        { label: 'Monthly', value: '' },
        { label: 'Yearly', value: '' },
        { label: 'Lifetime', value: '' },
        { label: 'Corporate', value: '' },
        { label: 'Partner', value: '' },
        { label: 'Canceled', value: '' },
      ];
      data.business = [
        { label: 'Organisation', value: '' },
        { label: 'Active Organisation', value: '' },
        { label: 'User', value: '' },
        { label: 'Active User', value: '' },
        { label: 'News Letter Sent', value: '' }
      ];
      this.setState({ data: data });
    });
  }

  render () {
    return (
      <Fragment>
        <Title title='Good Morning Italia' />
        <Responsive
          xsmall={<Users data={this.state.data} />}
          small={<Users data={this.state.data} />}
          medium={<Users data={this.state.data} />}
        />
         <ConversionRate data={this.state.conversionRate} />
      </Fragment>
    );
  }
}

export default Dashboard;
