/* eslint-disable indent */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef } from 'react';
import UserIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import CorporateIcon from '@material-ui/icons/BusinessCenter';
import GiftIcon from '@material-ui/icons/Redeem';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Admin, Resource } from 'react-admin';

import { restClient } from './lib/faDataProvider';
import UserList from './page/Users/component/UserList';
import UserEdit from './page/Users/component/UserEdit';
import AuthProvider from './page/Login/Provider/AuthProvider.js';
import FeathersClient from './page/DataProvider/FeathersClient';
import Dashboard from './page/Dashboard/component/Dashboard.js';
import LoginPage from './page/Login/component/LoginPage.js';
import Business from './page/Business/component/Business.js';
import Coupon from './page/Coupon/component/Coupon.js';
import CreateCoupon from './page/Coupon/component/CreateCoupon.js';
import CouponEdit from './page/Coupon/component/CouponEdit.js';
import CouponAction from './page/Coupon/component/CouponAction.js';
import Corporate from './page/Corporate/component/Corporate.js';
import CorporateEdit from './page/Corporate/component/CorporateEdit.js';
import BusinessUserEdit from './page/Business/component/EditBusinessUser.js';
import Gift from './page/Gifts/component/Gifts';
import GiftsEdit from './page/Gifts/component/GiftsEdit';
import NewsLetterStatus from './page/NewsLetterStatus/components/NewsLetterStatus';
import Einvoice from './page/Einvoice/EinvoiceTabs';
import NewsMetrics from './page/Newsmetrics/components/Newsmetrics';
import Edition from './page/Edition/components/Edition';
import AddEditorUsers from './page/EditorUsers/components/AddEditorUsers';
import EditiorUser from './page/DataProvider/EditiorUser';
import EditEditorUser from './page/EditorUsers/components/EditEditorUser';
import CreateEditorUser from './page/EditorUsers/components/CreateEditorUser';
import Widget from './page/Widget/components/Widget';
import CreateWidget from './page/Widget/components/CreateWidget';
import EditWidget from './page/Widget/components/EditWidget';
import PromotionCode from './page/PromotionCode/Promotioncode';
import CreatePromotionCode from './page/PromotionCode/CreatePromotionCode';
import EditPromotiomCode from './page/PromotionCode/EditPromotionCode';
import WidgetUsers from './page/WidgetUsers/WidgetUsersList';
import UserStatList from './page/UserStats/component/UserStatsList.js';
import CreateCorporateClient from './page/Corporate/component/CreateCorporateClient.js';

function App () {

  const ref = useRef(null);
  useEffect(() => {
    const handleClick = event => {
      if (event.newURL.includes('editor-users') && !event.oldURL.includes('editor-users')) {
        localStorage.setItem('activeSource', 'editorApi');
        window.location.reload();
      }
      if (!event.newURL.includes('editor-users') && event.oldURL.includes('editor-users')) {
        localStorage.setItem('activeSource', 'webApi');
        window.location.reload();
      }
    };
window.addEventListener('hashchange', handleClick);

    return () => {
      window.removeEventListener('hashchange', handleClick);
    };
  }, []);

  return (
  <Admin
    dataProvider={window.location.href.includes('editor-users') ? restClient(EditiorUser) : restClient(FeathersClient)}
    authProvider={AuthProvider}
    dashboard={Dashboard}
    loginPage={LoginPage}
  >
    <Resource
      name='users'
      icon={UserIcon}
      list={UserList}
      edit={UserEdit}
    />
    <Resource
      name='user-stats'
      icon={AssessmentIcon}
      list={UserStatList}
    />
    <Resource
      name='business'
      icon={BusinessIcon}
      list={Business}
      edit={BusinessUserEdit}
      options={{ label: 'Business' }}
    />
    <Resource
      name='coupons'
      icon={LoyaltyIcon}
      list={Coupon}
      options={{ label: 'Coupons' }}
      create={CreateCoupon}
      show={CouponAction}
      edit={CouponEdit}
    />
    <Resource
      name='corporate-edition'
      icon={CorporateIcon}
      list={Corporate}
      options={{ label: 'Corporate' }}
      create={CreateCorporateClient}
      edit={CorporateEdit}
    />
    <Resource
      name="gifting-details"
      icon={GiftIcon}
      list={Gift}
      options={{ label: 'Gifts' }}
      edit={GiftsEdit}
    />
    <Resource
      name='sync-news'
      icon={MailOutlineIcon}
      list={NewsLetterStatus}
      options={{ label: 'Newsletter' }}
    />
      <Resource
      name='e-invoice'
      icon={MailOutlineIcon}
      list={Einvoice}
      options={{ label: 'E-Invoice' }}
    />
    <Resource
      name='News-metrics'
      icon={AssessmentIcon}
      list={NewsMetrics}
      options={{ label: 'News-metrics' }}
    />
    <Resource
      name='editions-schedule'
      icon={SettingsApplicationsIcon}
      list={Edition}
      options={{ label: 'Editions-schedule' }}
    />
    <Resource
      name='editor-users'
      icon={UserIcon}
      forwardRef={ref}
      list={AddEditorUsers}
      options={{ label: 'Editor-Users' }}
      create={CreateEditorUser}
      edit={EditEditorUser}

    />
    <Resource
      name='widget'
      icon={LoyaltyIcon}
      list={Widget}
      options={{ label: 'Widget' }}
      create={CreateWidget}
      edit={EditWidget}
    />
    <Resource
      name='widget-users'
      icon={UserIcon}
      list={WidgetUsers}
      options={{ label: 'Widget-Users' }}
    />
    <Resource
      name='PromotionCode'
      icon={LoyaltyIcon}
      list={PromotionCode}
      options={{ label: 'Promotion-Code' }}
      create={CreatePromotionCode}
      edit={EditPromotiomCode}
    />
  </Admin>
  );
  };
export default App;
