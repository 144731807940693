import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK
} from 'react-admin';
import axios from 'axios';

const AuthProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;

    const url = `${process.env.REACT_APP_API_BASE_URL}/admin-login`;
    return axios.post(url, { email : username, password : password, action: 'adminLogin' }, {
      headers: {
        'Content-Type': 'application/json',
        cert: 'admin'
      }
    }).then((response) => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', username);
      return Promise.resolve();
    }).catch(() => {
      return Promise.reject(new Error('User Name or Password is incorrect'));
    });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject(new Error('Error while authenticating user'));
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject(new Error('Check UserName'));
  }
  return Promise.reject(new Error('Unknown Method'));
};

export default AuthProvider;
