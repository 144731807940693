import React, { useState } from 'react';
import {
  DateInput,
  TextInput,
  Edit,
  AutocompleteInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  DeleteButton,
  SelectInput,
  Confirm,
  Button,
  useRedirect,
  useNotify
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from './CancelButton';
import './Styles/userStyle.css';
import { provinceOptions } from '../../../lib/config';
import { purgeUserPermanently } from '../../DataProvider/PurgeUser'
import _ from 'lodash';
import { useEffect } from 'react';


const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

function FormToolbar (props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage]= useState(false);
  const [isCorporateUser, setCorporateUser] = useState(false);
  const [name, setName] = useState('');
  const redirect = useRedirect();
  const notify = useNotify();
  useEffect(()=>{
    if (props.record.role && (props.record.role.includes('corporate_user') || props.record.role.includes('corporate_admin') || props.record.role.includes('business_admin') || props.record.role.includes('business_user'))){
      setCorporateUser(true);
      if (props.record.role.includes('corporate_user') || props.record.role.includes('corporate_admin')){
        setMessage('corporate');
        setName(props.record.subscription.personal.subscription_type);
      }else{
        setMessage('organisation');
        setName(props.record.organisation.name);
      }
    }
  }, [props.id]);
  const handlePurge = async () =>{
  const response = await purgeUserPermanently(props.id);
   setOpen(false);
   if(_.has(response,'status') && response.status === 200){
    notify(`User Purged Successfully!`, { type: 'info' });
    redirect('/users');
   }
   else{
    notify(`Purge Failed!`, { type: 'danger' });
   }
  }
  return (
    <Toolbar {...props} >
      <SaveButton />
      <DeleteButton undoable={false} style={{ position: 'absolute', right: '20px' }} 
      label='DELETE'
        confirmContent={isCorporateUser ? (
          <div>
            <p >
              <span style={{ fontWeight: 'bold' }}>{props.record.email}&nbsp;&nbsp;</span>{`belongs to the ${name} ${message}. Do you still want to delete it?`}
              </p>
            <br />
            <span style={{ color: 'red' }}>
              <span style={{ fontWeight: 'bold' }}>NOTE: </span>
              If you proceed, this might cause cosmetic issues on the gmi webapp. Please reach out to your dev team.
            </span>
          </div>
        ): ("Are you sure you want to delete this ?")}
      />
        <Button style={{ position: 'absolute', right: '164px', color:'red'}} label="Delete from Platform" onClick={()=>{setOpen(true)}} />
        <Confirm
                isOpen={open}
                title="Delete from Platform"
                content="This User will be deleted permanently along with all its data. Are you sure?"
                onConfirm={()=>{handlePurge()}}
                onClose={()=>{setOpen(false)}}
            />
    </Toolbar>
  );
};

const UserPersonalTab = withStyles(styles)(({ classes, ...props }) =>
  <Edit title='Update' actions={<CancelButton />} {...props} >
    <SimpleForm toolbar={<FormToolbar id={props.id} />}>
      <TextInput source='id' formClassName={classes.inlineBlock} style={{ fontStyle: 'italic' }} readonly disabled />
      <TextInput label='Name' source='nicename' formClassName={classes.inlineBlock} />
      <TextInput label='Surname' source='surName' formClassName={classes.inlineBlock} />
      <TextInput source='email' formClassName={classes.inlineBlock} />
      <DateInput source='dob' formClassName={classes.inlineBlock} />
      <TextInput label='Codice fiscale' source='cf' formClassName={classes.inlineBlock} />
      <TextInput source='city' formClassName={classes.inlineBlock} />
      <AutocompleteInput source='gender' formClassName={classes.inlineBlock} choices={[
        { id: 'male', name: 'Male' },
        { id: 'female', name: 'Female' }
      ]} />
      <SelectInput
        source='province'
        choices={provinceOptions}
        formClassName={classes.inlineBlock}
        optionValue='id'
        optionText='name'
      />
      <TextInput source='job' formClassName={classes.inlineBlock} />
      <TextInput source='language' formClassName={classes.inlineBlock} />
      <TextInput source='nation' formClassName={classes.inlineBlock} />
      <TextInput source='campaign' formClassName={classes.inlineBlock} readonly />
    </SimpleForm>
  </Edit>
);

export default UserPersonalTab;
