import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';

import UserHistory from './UserHistory';
import UserPersonalTab from './UserPersonalTab';
import UserSubscriptionTab from './UserSubscriptionTab';
import UserBusinessTab from './UserBusinessTab';
import ManageRole from './ManageRole';
import UserTransactions from './UserTransactions';

class UserEdit extends Component {
  constructor (props) {
    super(props);
    this.state = { value: 0 };
  }

 handleChange = (event, value) => {
   this.setState({ value });
 };

 render () {
   const { value } = this.state;
   return (
     <Fragment>
       <Tabs
         fullWidth
         centered
         value={value}
         indicatorColor='primary'
         onChange={this.handleChange}
       >
         <Tab label='Personal Info' style={{ fontWeight: 'bold' }} />
         <Tab label='Subscription Info' style={{ fontWeight: 'bold' }} />
         <Tab label='Business Info' style={{ fontWeight: 'bold' }} />
         <Tab label='User History' style={{ fontWeight: 'bold' }} />
         <Tab label='Role' style={{ fontWeight: 'bold' }} />
         <Tab label='Transactions' style={{ fontWeight: 'bold' }} />
       </Tabs>
       <Divider />
       {value === 0 && <UserPersonalTab {...this.props} />}
       {value === 1 && <UserSubscriptionTab {...this.props} />}
       {value === 2 && <UserBusinessTab {...this.props} />}
       {value === 3 && <UserHistory userId={this.props.id} />}
       {value === 4 && <ManageRole {...this.props} />}
       {value === 5 && <UserTransactions {...this.props} />}
     </Fragment>
   );
 }
}

export default UserEdit;