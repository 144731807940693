import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

const getNewsMetrics = async () => {
  const lastDate = `${moment().subtract(9, 'days').format('YYYY-MM-DD')} 00:00:00`;
  const currentDate = `${moment().format('YYYY-MM-DD')} 23:59:00`;
  const url = `${process.env.REACT_APP_API_BASE_URL}/sync-news?action=newsMetrics&date_published[$gte]=${lastDate}&date_published[$lt]=${currentDate}&$sort[createdAt]=1&version=general&$select=content_json`;
  const token = localStorage.getItem('token');

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        cert: 'admin',
        platform: 'ops',
        action: 'newsMetrics'
      }
    })
    .then(response => {
      if (_.has(response, 'data')) {
        return response.data;
      }
    })
    .catch(err => {
      console.log(err.message);
    });
};
export default getNewsMetrics;
