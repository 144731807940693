import axios from 'axios';
export const getUsersList = async () => {
  const url = `${process.env.REACT_APP_EDITOR_API_BASE_URL}/users?action=mergeNameFields`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err.message);
  });
};

export const updateUsersContent = async (data, _id) => {
  const url = `${process.env.REACT_APP_EDITOR_API_BASE_URL}/users/${_id}`;
  const token = localStorage.getItem('token');
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }).then(response => {
    return response;
  }).catch((err) => {
    console.log(err);
  });
};

export const getVersionList = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/versions?action=fetchAll`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err.message);
  });
};

export const updateSchedule = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/versions`;
  const token = localStorage.getItem('token');
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      platform: 'ops',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    console.log(response, 'Response ----------');
    return response;
  }).catch((err) => {
    console.log(err);
  });
};
