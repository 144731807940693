import feathers from 'feathers-client';
import rest from 'feathers-rest/client';
import hooks from 'feathers-hooks';
import superagent from 'superagent';


const host = `${process.env.REACT_APP_API_BASE_URL}`;

const app = feathers()
  .configure(hooks())
  .configure(rest(host).superagent(superagent));

async function authHook (hook) {
  const token = localStorage.getItem('token');
  hook.params.headers = Object.assign({}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    cert: 'admin',
    platform: 'ops'
  }, hook.params.headers);
}

export default {
  service (url) {
    let s = app.service(url);
    s.before({
      all: authHook
    });
    return s;
  }
};
