
import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  SearchInput
} from 'react-admin';
import PeriodInput from '../../lib/helper/PeriodInput';

export default class EinvoiceRegular extends Component {
  componentWillMount() {
    localStorage.setItem('actionKey', 'getRegularTransaction');
  }
  render () {
    const MoveFilter = (props, i) => (
      <Filter {...props}>
        <SearchInput source='q' alwaysOn />
        <PeriodInput source='transactionDate' />
      </Filter>
    );
    return (
      <List bulkActionButtons={false} filters={<MoveFilter />} pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />} {...this.props}>
        <Datagrid >
          <TextField source='transactionDate' />
          <TextField source='first_name' label='First Name' sortable />
          <TextField source='sur_name' label='Surname' sortable />
          <TextField source='email' label='Email' sortable />
          <TextField source='business_piva' label='Vat' sortable />
          <TextField source='fiscal_code' label='Fiscal Code' sortable />
          <TextField source='city' label='City' sortable={false} />
          <TextField source='postal_code' label='Postal Code' sortable={false} />
          <TextField source='country' label='Country' sortable={false} />
          <TextField source='amount' label='Amount' sortable={false} />
          <TextField source='payment_status' label='Payment Status' sortable={false} />
          <TextField source='payment_for' label='Payment Type' sortable={false} />
        </Datagrid>
      </List>
    );
  }
}
