import React, { Component } from 'react';
import { Filter, AutocompleteInput, TextInput } from 'react-admin';

import { getReferrerOptions } from '../../DataProvider/FilterOptions';

class UserFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      choices: {}
    };
  }

  async componentWillMount () {
    const data = await getReferrerOptions();
    await this.setState({ choices: data });
  }

  render () {
    return (
      <Filter {...this.props}>
        <AutocompleteInput label='Subscription Type' source='subscription_type' choices={[
          { id: 'trial', name: 'Trial' },
          { id: 'month', name: 'Month' },
          { id: 'lifetime', name: 'Lifetime' },
          { id: 'year', name: 'Year' },
          { id: 'expired', name: 'Expired' },
          { id: 'partner', name: 'Partner' },
          { id: 'corporate', name: 'Corporate' }
        ]} />
        <AutocompleteInput label='Mailchimp Status' source='mailChimpStatus' choices={[
          { id: 'subscribed', name: 'Subscribed' },
          { id: 'unsubscribed', name: 'Unsubscribed' }
        ]} />
        <AutocompleteInput label='Platform' source='os_type' choices={[
          { id: 'web', name: 'Web' },
          { id: 'ios', name: 'ios' }
        ]} />
        <AutocompleteInput label='Auto Renew Status' source='autoRenewStatus' choices={[
          { id: '1', name: 'True' },
          { id: '0', name: 'False' }
        ]} />
        <AutocompleteInput label='Referral Type' source='referrer_id' choices={this.state.choices} />
        <TextInput label='Search' source='q' alwaysOn />
      </Filter>
    );
  }
}

export default UserFilter;
