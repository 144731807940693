import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
export default class ConversionRate extends Component {
  constructor (props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Conversions in last 30 days',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
    };
  }

  componentWillReceiveProps (nextProps) {
    if (this.props !== nextProps) {
      try {
        const { data } = nextProps;
        const dateArray = [];
        const result = [
          {
            name: 'Trial',
            data: []
          },
          {
            name: 'Coupon',
            data: []
          },
          {
            name: 'Gift',
            data: []
          },
          {
            name: 'Upgrade Plan',
            data: []
          },
          {
            name: 'Promotion',
            data: []
          }
        ];
        let sortedDateArray = Object.keys(data).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        for (let statItems of sortedDateArray) {
          const item = data[statItems];
          dateArray.push(statItems);
          const { trial_to_month, trial_to_year, trial_to_lifetime, coupon_to_month, coupon_to_year, coupon_to_lifetime, gift_to_month, gift_to_year, gift_to_lifetime, month_to_year, month_to_lifetime, year_to_lifetime , promotion_to_month, promotion_to_year, promotion_to_lifetime} = item;
          let sumOfTrialConversion = trial_to_month + trial_to_year + trial_to_lifetime;
          let sumOfCouponConversion = coupon_to_month + coupon_to_year + coupon_to_lifetime;
          let sumOfGiftConversion = gift_to_month + gift_to_year + gift_to_lifetime;
          let sumOfUpgradeConversion = month_to_year + month_to_lifetime + year_to_lifetime;
          let sumOfPromotionConversion = promotion_to_month + promotion_to_year + promotion_to_lifetime;
          result[0].data.push(sumOfTrialConversion);
          result[1].data.push(sumOfCouponConversion);
          result[2].data.push(sumOfGiftConversion);
          result[3].data.push(sumOfUpgradeConversion);
          result[4].data.push(sumOfPromotionConversion);
        }

        this.setState({
          series: result,
          options: {
            xaxis: {
              categories: dateArray,
            }
          },
        });
      } catch (error) {
        console.log('[error][conversionrate]', error);
      }
    }
  }

  render () {
    return (
      <div className='app'>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type='line'
          width='800'
        />
      </div>

    );
  }
}
