const moment = require('moment');
const _ = require('lodash');
const slugify = require('slugify');
const func = require('../helper/localTimeAndDate');
const widget = require('../helper/widget');
Object.defineProperty(exports, '__esModule', {
  value: true
});

var _extends =
 Object.assign ||
 function (target) {
   for (var i = 1; i < arguments.length; i++) {
     var source = arguments[i];
     for (var key in source) {
       if (Object.prototype.hasOwnProperty.call(source, key)) {
         target[key] = source[key];
       }
     }
   }
   return target;
 };

var _reactAdmin = require('react-admin');

var _debug = require('debug');

var _debug2 = _interopRequireDefault(_debug);

var _objectDiff = require('object-diff');

var _objectDiff2 = _interopRequireDefault(_objectDiff);

function _interopRequireDefault (obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var dbg = (0, _debug2.default)('ra-data-feathers:rest-client'); // eslint-disable-line

function getIdKey (_ref) {
  var resource = _ref.resource;

  var options = _ref.options;
  if (resource === 'editor-users') {
    return (options[resource] && options[resource]._id) || options._id || '_id';
  } else {
    return (options[resource] && options[resource].id) || options.id || 'id'; // eslint-disable-line
  }
}

exports.default = function (client) {
  var options =
  arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  // var usePatch = !!options.usePatch;
  var mapRequest = function mapRequest (type, resource, params) {
    const activeSource = localStorage.getItem('activeSource') || '';
    if (activeSource.length && resource !== 'editor-users' && activeSource === 'editorApi') {
      return;
    }
    if (resource === 'business') {
      resource = 'organisation';
    }
    if (resource === 'coupons') {
      resource = 'coupon-types';
    }
    if (type === 'CREATE' && resource === 'corporate-edition') {
      resource = 'corporate';
    }
    if (resource === 'editor-users') {
      resource = 'users';
    }
    if (resource === 'PromotionCode') {
      resource = 'promotion';
    }
    if (resource === 'user-stats') {
      resource = 'user-analytics';
    }
    // else if (resource === 'gifting-details') {
    //   resource = 'gifting-details?action=giftDetailListing';
    //  }
    var idKey = getIdKey({ resource: resource, options: options });
    dbg(
      'type=%o, resource=%o, params=%o, idKey=%o',
      type,
      resource,
      params,
      idKey
    );
    var service = client.service(resource);
    var query = {};
    switch (type) {
      case _reactAdmin.GET_MANY:
        var ids = params.ids || [];
        query[idKey] = { $in: ids };
        query['$limit'] = ids.length;
        return service.find({ query: query });
      case _reactAdmin.GET_MANY_REFERENCE:
        if (params.target && params.id) {
          query[params.target] = params.id;
        }
        break;
      case _reactAdmin.GET_LIST:
        var _ref2 = params.pagination || {};

        var page = _ref2.page;

        var perPage = _ref2.perPage;

        var _ref3 = params.sort || {};

        var field = _ref3.field;

        var order = _ref3.order;

        let sortKey;
        if(resource === 'organisation') {
          sortKey = '$sort[Name]';
        } else {
          sortKey = '$sort[' + (field === 'id' ? idKey : field) + ']';
        }
        dbg('field=%o, sort-key=%o', field, sortKey);
        var sortVal = order === 'DESC' ? -1 : 1;
        if (perPage && page) {
          if(resource === 'user-analytics') {
            if(_.has(params.filter,'yearFilter') && params.filter.yearFilter) {
              query['year'] = params.filter.yearFilter;
            }
          }
          query['$limit'] = perPage;
          query['$skip'] = perPage * (page - 1);
        }
        if (order) {
          query[sortKey] = JSON.stringify(sortVal);
        }
        Object.assign(query, _reactAdmin.fetchUtils.flattenObject(params.filter));
        dbg('query=%o', query);
        if (resource === 'gifting-details') {
          query['action'] = 'giftDetailListing';
        }
        if (resource === 'e-invoice') {
          let actionParam = localStorage.getItem('actionKey');
          query['action'] = actionParam;
        }
        if (resource === 'widget-users') {
          query.action = 'widgetUsers';
        } else { 
          return service.find({ query: query })
        }
        break;
        
      case _reactAdmin.GET_ONE:
        return service.get(params.id);
      case _reactAdmin.UPDATE:
        if (resource === 'widget') {
          const data = params.data;
          const updatedWidgetData = widget.prepareWidgetData(data);
          return service.patch(params.id, updatedWidgetData);
        }
        const activeSource = localStorage.getItem('activeSource');
        const usePatch = activeSource !== 'editorApi';
        const storedData = params.data;
        if (usePatch) {
          if (resource === 'gifting-details') {
            const { gift_status } = params.data;
            const changeStatus = gift_status === false ? '0' : '1';
            params.data.gift_status = changeStatus;
          }

          var data = params.previousData
      ? (0, _objectDiff2.default)(params.previousData, params.data) // eslint-disable-line
            : params.data;
          if (params.previousData.email) {
            data.oldUserEmail = params.previousData.email;
            data.nicename = params.previousData.nicename;
          }
          // overriding the nicename, mailChimpStatus if user has changed it
          if (params.data.nicename) {
            data.nicename = params.data.nicename;
          }
          // adding sub_id
          if (
            _.has(params.data, 'sub_id') &&
      params.data.sub_id &&
      (_.has(data, 'subscription_type') ||
       _.has(data, 'subscription_expiration_date_formatted') ||
       _.has(data, 'mailChimpStatus') || _.has(data, 'subscription_plan'))
          ) {
            data.sub_id = params.data.sub_id;
            if (!_.has(data, 'subscription_type')) {
              data.subscription_type = params.data.subscription_type;
            }
            if (!_.has(data, 'mailChimpStatus')) {
              data.mailChimpStatus = params.data.mailChimpStatus;
            }
            if (!_.has(data, 'subscription_expiration_date_formatted')) {
              data.subscription_expiration_date_formatted =
        params.data.subscription_expiration_date_formatted;
            }
          }
          if (!_.has(params.data, 'sub_id')) {
            data.mailChimpStatus = params.data.mailChimpStatus;
            data.subscription_type = params.data.subscription_type;
            data.subscription_expiration_date_formatted =
            params.data.subscription_expiration_date_formatted;
          }
          if (params.data.mailChimpStatus) {
            // data.mailChimpStatus = params.data.mailChimpStatus;
          }
          data.platform = 'ops';
          data.action = '';
          if (resource === 'users' && activeSource !== 'editorApi') {
            data.action = 'userUpdate';
          } else if (resource === 'organisation') {
            data.action = 'fetchOrganisation';
          }

          if (data.mailChimpStatus === true) {
            data.mailChimpStatus = 'subscribed';
          } else if (data.mailChimpStatus === false) {
            data.mailChimpStatus = 'unsubscribed';
          }
          if (
            typeof data.subscription_expiration_date_formatted !== 'undefined' &&
      data.subscription_expiration_date_formatted !== null &&
      data.subscription_expiration_date_formatted !== ''
          ) {
            data.subscription_expiration_date_formatted = moment(
              data.subscription_expiration_date_formatted
            ).format('YYYY-MM-DD');
          }

          return service.patch(params.id, data);
        }
        if (activeSource === 'editorApi') {
          delete storedData.id; // fix for editor users patch not working
          let dataToUpdate = storedData;

          if (resource === 'users' && !_.isEmpty(params)) {
            const { data, previousData } = params;
            if (data.passwordText !== previousData.passwordText) {
              dataToUpdate = Object.assign({}, storedData, { action:'passwordUpdate' });
            }
          }

          return service.patch(params.id, dataToUpdate);
        } else {
          return service.update(params.id, params.data);
        }
      case _reactAdmin.CREATE:
        if (resource === 'users') {
          params.data.action = 'syncWebUser';
        }

        if (resource === 'coupon-types') {
          const data = Object.assign({}, params.data);
          data.long_description = data.short_description;
          data.status = '1';
          data.slug = slugify(data.name, {
            lower: true
          });
          data.metadata = { logo: 'gmi.jpg' };
          data.prefix = data.prefix.toUpperCase();
          params.data = data;
        }

        if (resource === 'corporate') {
          let data = Object.assign({}, params.data);
          data.status = '0';
          data.metadata = {
            contact: params.data.metadata
          };
          params.data = data;
        }
        if (resource === 'widget') {
          const data = params.data;
          const updatedDataOfWidget = widget.prepareWidgetData(data);
          params.data = updatedDataOfWidget;
        }

        if (resource === 'promotion') {
          params.data.max_user = Number(params.data.max_user);
        }
        return service.create(params.data);
      case _reactAdmin.DELETE:
        if (resource === 'organisation') {
          return service.patch(params.id, {
            action: 'deleteBusinessFromAdmin',
            status: '0'
          });
        }
        return service.remove(params.id);
      case _reactAdmin.DELETE_MANY:
        return Promise.all(
          params.ids.map(function (id) {
            return service.remove(id);
          })
        );
      default:
        return Promise.reject(
          new Error('Unsupported FeathersJS authClient action type')
        );
    }
  };

  var mapResponse = function mapResponse (response, type, resource, params) {
    var idKey = getIdKey({ resource: resource, options: options });
    switch (type) {
      case _reactAdmin.GET_ONE:
        if (_.has(response, ['subscription', 'personal', 'mailChimpStatus'])) {
          switch (response.subscription.personal.mailChimpStatus) {
            case 'subscribed':
              response.mailChimpStatus = true;
              break;
            case 'unsubscribed':
              response.mailChimpStatus = false;
              break;
            default:
              response.mailChimpStatus = false;
              break;
          }
        }
        response.subscription_type =
     typeof response.subscription_type !== 'undefined' &&
     response.subscription_type !== '' &&
     response.subscription_type !== null
       ? response.subscription_type.toLowerCase().trim()
       : '';
        if (
          response.subscription_plan === 'corporate' &&
     response.subscription_type !== ''
        ) {
          const subscriptionType = response.subscription_type.split('_');
          response.subscription_type =
      subscriptionType[0] +
      (subscriptionType.slice(1).length > 0
        ? ` (${subscriptionType.slice(1).join(' ')})`
        : '');
        }
        if (resource === 'widget') {
          const data = widget.prepareData(response);
          response = data;
        }
        return { data: _extends({}, response, { id: response[idKey] }) };
      case _reactAdmin.UPDATE:
      case _reactAdmin.DELETE:
        return { data: _extends({}, response, { id: response[idKey] }) };
      case _reactAdmin.DELETE_MANY:
        return {
          data: response.map(function (record) {
            return record[idKey];
          })
        };
      case _reactAdmin.CREATE:
        return {
          data: _extends({}, params.data, response, { id: response[idKey] })
        };
      case _reactAdmin.GET_MANY_REFERENCE: // fix GET_MANY_REFERENCE missing id
      case _reactAdmin.GET_MANY: // fix GET_MANY missing id
      case _reactAdmin.GET_LIST:
        if (resource === 'users') {
          let users = [];
          response.data.forEach(item => {
            if (item.autoRenewStatus === 1) {
              item.autoRenewStatus = true;
            } else {
              item.autoRenewStatus = false;
            }
            if (item.mailChimpStatus === 'subscribed') {
              item.mailChimpStatus = true;
            } else if (item.mailChimpStatus === 'unsubscribed') {
              item.mailChimpStatus = false;
            }
            if (idKey !== 'id') {
              item.id = item[idKey];
            }
            users.push(item);
          });
          response.data = users;          
        }  
        else if (resource === 'business') {
          let business = { data: [], total: 0 };
          response.data.forEach(resp => {
            business.data.push({
              id: resp.id,
              name: resp.name,
              plan: resp.plan,
              contact: resp.contact,
              city: resp.city,
              address: `${resp.address_1} ${resp.address_2}, ${resp.post_code}`,
              vat_number: resp.vat_number,
              fiscal_code: resp.fiscal_code,
              status: resp.status,
              recipient_code: resp.recipient_code,
              province: resp.province,
              post_code: resp.post_code
            });
          });
          business.total = response.total;
          response = business;
        } else if (resource === 'coupons') {
          const coupons = [];
          response.data.forEach((item, i) => {
            const totalCoupon = item.total_coupon;
            const redeemedCoupon = item.redeemed_coupon;
            const redeemedRate = (redeemedCoupon === 0 && totalCoupon === 0) ? 0 : redeemedCoupon / totalCoupon;
            item.iD = item.id;
            item.redeemed_rate = (redeemedRate * 100).toFixed(2) + ' %';
            switch (item.status) {
              case 1:
                item.status = 'Active';
                break;
              case 2:
                item.status = 'Redeemed';
                break;
              default:
                item.status = '';
                break;
            }
            coupons.push(item);
          });
          response.data = coupons;
        } else if (resource === 'corporate-edition') {
          let rows = [];
          if (Array.isArray(response)) {
            response.map((corporateItem, index) => {
              const item = {
                id: `${corporateItem.corporate.id}/${corporateItem.version.id}`,
                sr_no: index + 1,
                name: corporateItem.corporate.name,
                version: corporateItem.version.name,
                version_id: corporateItem.version.id,
                edition:
                corporateItem.corporate.name + ' ' + ((!_.isEmpty(corporateItem.version) && _.has(corporateItem.version, 'name')) ? corporateItem.version.name : ''),

                language: _.has(corporateItem, ['version', 'language'])
                  ? corporateItem.version.language === 'it'
                    ? 'Italian'
                    : corporateItem.version.language === 'en'
                      ? 'English'
                      : corporateItem.version.language === 'hi'
                        ? 'Hindi'
                        : corporateItem.version.language
                  : 'Italian'
              };
              return rows.push(item);
            });
            response.data = rows;
            response.total = rows.length;
          }
        } else if (resource === 'editor-users') {
          response.data.forEach((elem, i) => {
            elem.srNo = params.pagination.page * params.pagination.perPage - params.pagination.perPage + i + 1;
            elem.idNo = elem.id;
            elem.id = elem._id;
          });
        } else if (resource === 'widget-users') {
          let resp = [];
          response.data.forEach((elem, i) => {
            elem.srNo = params.pagination.page * params.pagination.perPage - params.pagination.perPage + i + 1;
            resp.push(elem);
          });
          response.data = resp;
        } else if (resource === 'widget') {
          const resp = response.data;
          let widgetPreparedData = [];
          resp.forEach((elem, i) => {
            const preparedDataOfWidget = widget.prepareData(elem);
            preparedDataOfWidget.srNo = params.pagination.page * params.pagination.perPage - params.pagination.perPage + i + 1;
            widgetPreparedData.push(preparedDataOfWidget);
          });
          response.data = widgetPreparedData;
        } else if (resource === 'PromotionCode') {
          const resp = response.data;
          let promotionCodeList = [];
          resp.forEach((elem, i) => {
            const date = new Date(elem.expiry_date);
            elem.expiry_date = date.toLocaleDateString();
            elem.srNo = params.pagination.page * params.pagination.perPage - params.pagination.perPage + i + 1;
            elem.redemeed = elem.redemeedPromoton;
            promotionCodeList.push(elem);
          });
          response.data = promotionCodeList;
        } else if (resource === 'gifting-details') {
          let rows = [];
          if (Array.isArray(response)) {
            response.map((giftsItem, index) => {
              const item = {
                id: giftsItem.id,
                sr_no: index + 1,
                Gift: giftsItem.gift,
                nicename: `${giftsItem.nicename} ${giftsItem.surName}`,
                senderEmail: giftsItem.senderEmail,
                senderName: giftsItem.senderName,
                receiverName: giftsItem.receiverName,
                receiverEmail: giftsItem.receiverEmail,
                giftSubscriptionPeriod: giftsItem.giftSubscriptionPeriod,
                redemptionStatus: giftsItem.redemptionStatus !== '0',
                giftExpiryDate: moment(giftsItem.giftExpiryDate).format('H:mm:ss')
              };

              return rows.push(item);
            });
            response.data = rows;
            response.total = rows.length;
          }
        } else if (resource === 'sync-news') {
          const location = window.location.href;
          let filteredData = [];
          if (location.includes('ASC')) {
            filteredData = _.has(response, 'data') && response.data.sort((a, b) => {
              return a.id - b.id;
            });
          } else {
            filteredData = _.has(response, 'data') && response.data.sort((a, b) => {
              return b.id - a.id;
            });
          }
          const newLetterStatus = [];
          filteredData && filteredData.map((data, index) => {
            const obj = {
              id: data.id,
              publishedAt: data.date_published,
              url: data.url,
              title:
        data.title.split(' ').length > 5
          ? `${data.title.substr(0, 20)}...`
          : data.title,
              edition: `${data.corporate} (${data.version})`,
              status:
        _.has(data, ['delivery', 'status']) &&
        data.delivery.status === 'scheduled'
          ? func.localTimeAndDate(data.delivery.time, data.delivery, data)
          : data.delivery.status
            };
            return newLetterStatus.push(obj);
          });
          response.data = newLetterStatus;
        } else if (resource === 'e-invoice') {
          const actionKey = localStorage.getItem('actionKey');
          const EinvoiceData = [];
          response.data.map((data, index) => {
            const amountString = data.amount;
            let amount;
            if (amountString && amountString.length && data.amount.includes(',')) {
              amount = data.amount.replaceAll(',', '');
            } else {
              amount = data.amount;
            }
            let displayedAmount = '';
            if (amount && amount.length && actionKey !== 'getBusinessTransaction') {
              const amountInString = `${amount.substring(0, amount.length - 2)}.${amount.substring(amount.length - 2)}`;
              const amountInNumber = Number(amountInString);
              const convertedAmount = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(amountInNumber);
              displayedAmount = convertedAmount;
            } else {
              // eslint-disable-next-line no-mixed-operators
              displayedAmount = amount && amount.length && amount + ' \t€' || '';
            }

            const obj = {
              id: index + 1,
              transactionDate: data.transaction_date !== '' ? moment(data.transaction_date).format('DD/MM/YYYY') : '',
              first_name: data.first_name,
              name: data.name,
              email: data.email,
              city: data.city,
              sur_name: data.sur_name,
              payment_for: data.payment_for_regular,
              business_name: data.business_name,
              postal_code: data.postal_code,
              codice_fiscale: data.fiscal_code,
              country: data.country,
              province: data.province,
              address: data.address,
              amount: displayedAmount,
              payment_status: data.payment_status,
              payment_type: data.payment_type,
              vat_number: data.vat_number,
              transaction_id: data.transaction_id
            };
            return EinvoiceData.push(obj);
          });
          response.data = EinvoiceData;
          // response.total = EinvoiceData.data.length;
        }

        return response;
      default:
        return response;
    }
  };

  return function (type, resource, params) {
    return mapRequest(type, resource, params).then(function (response) {
      return mapResponse(response, type, resource, params);
    });
  };
};

module.exports = exports['default'];
