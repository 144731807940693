import React from 'react';
import './EditionSchedule.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import swal from 'sweetalert';
import { getVersionList, updateSchedule } from '../../DataProvider/EditionConfiguration';
const dayOfWeekList = {
  sunday:0,
  monday:1,
  tuesday:2,
  wednesday:3,
  thursday:4,
  friday:5,
  saturday:6
};
function imageFormatter (cell, row) {
  return (<div className='edition-container' src={cell}><div className='img-container'><img className='logo-container' src={`${cell.image}`} alt='Logo' /></div>&nbsp;&nbsp;<div className='version-container'><span className='text-container'>{cell.name}&nbsp;({cell.edition})</span></div></div>);
}
export default class EditionsSchedule extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      list: [],
      data: [],
      headerDates:[],
      versionListArray:[],
      clicked:false,
      listOfDay : [],
      checked:false,
    };
  }
  notificationSchedule = (scheduleMetaData) => {
    let defaultSchedule = {
      sunday:false,
      monday:false,
      tuesday:false,
      wednesday:false,
      thursday:false,
      friday:false,
      saturday:false
    };
    const weekConfig = _.invert(dayOfWeekList);
    if (_.has(scheduleMetaData, 'time')) {
      if (scheduleMetaData.time.day_of_week === '*') {
        defaultSchedule = Object.keys(defaultSchedule).reduce((acc, key) => { acc[key] = true; return acc; }, {});
      }
    }
    if (_.has(scheduleMetaData, 'time') && scheduleMetaData && Array.isArray(scheduleMetaData.time.day_of_week)) {
      scheduleMetaData.time.day_of_week.forEach(i => {
        if (i >= 0 && i <= 6) {
          defaultSchedule[weekConfig[i]] = true;
        }
      });
    } else {
      defaultSchedule = {
        ...defaultSchedule,
      };
    }
    return defaultSchedule;
  }
  handleAlert = (dataFormatter, checked, name) =>
    new Promise((resolve, reject) => {
      swal({
        title: 'Are you sure?',
        text: `You ${!checked ? "don't" : ''} want to send ${name} edition on ${dataFormatter}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      })
        .then((value) => {
          resolve(value);
        });
    });
  stateUpdateFunction= (versionList) => {
    const { list } = this.props;
    const dataList = [];
    versionList && versionList.forEach(elem => {
      const versionInformation = list.filter(item => item.version.slug === elem.slug);
      const template = _.has(versionInformation[0], 'template');
      const version = _.has(versionInformation[0], 'version');
      const corporate = _.has(versionInformation[0], 'corporate');
      const result = this.notificationSchedule(elem.metadata.notification_schedule);
      _.has(elem.metadata, 'notification_schedule') &&
      dataList.push({
        ...elem,
        ...result,
        id: { image: template && versionInformation[0].template.logo_uri, edition: version && versionInformation[0].version.name, name:corporate && versionInformation[0].corporate.name },
      });
    });

    this.setState({ data: dataList });
  }

  async componentWillMount () {
    const response = await getVersionList();
    const versionList = response.data;
    this.setState({ versionListArray: versionList });
    var defaultWeekdays = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e').startOf('week').isoWeekday(i + 1).format('dddd');
    });
    this.setState({ headerDates: defaultWeekdays, });
    this.stateUpdateFunction(versionList);
  }

  handleClick = async (e, row, cell = '', dataFormatter = '') => {
    const selectedElement = e.target;
    const checked = e.target.checked;
    const editionName = _.has(row, 'id') ? `${row.id.name} (${row.id.edition})` : '';
    const res = await this.handleAlert(dataFormatter, checked, editionName);
    if (res) {
      const object = {
        'action': 'updateNotificationSchedule',
        'data': {
          'version': row.slug,
          'dayOfWeek': dayOfWeekList[dataFormatter.toLowerCase()],
          'value': checked
        }
      };
      const response = await updateSchedule(object);
      const list = [];
      const getList = this.state.versionListArray;
      getList.forEach(elem => {
        if (_.has('data', response) && elem.slug === response.data[0].slug) {
          list.push(response.data[0]);
        } else {
          list.push(elem);
        }
      });
      this.setState({ versionListArray: list });
      this.stateUpdateFunction(list);
    } else {
      selectedElement.checked = !checked;
      row[dataFormatter] = selectedElement.checked;
    }
  };

  statusFormatter= (cell, row, dataFormatter) => {
    return (<div><input type='checkbox' className='checkbox-field' defaultChecked={cell} onClick={(e) => this.handleClick(e, row, cell, dataFormatter)} /></div>);
  }

  render () {
    const { headerDates } = this.state;
    return (
      <div>
        <BootstrapTable
          data={this.state.data}
          tableContainerClass='editions-schedule'
          className='scroll-class container-fluid mt-2'
        >
          <TableHeaderColumn thStyle={{ padding:'8px', width:'33%' }} tdStyle={{ padding:'8px', width:'32%' }} dataFormat={imageFormatter} dataField='id' isKey>Editions Name</TableHeaderColumn>
          {headerDates.map((elem, i) => {
            const dayOfWeek = elem.toLowerCase();
            return <TableHeaderColumn thStyle={{ textAlign:'center', padding:'8px', width:'10%' }} tdStyle={{ textAlign:'center', padding:'8px', width:'10%' }} key={uuidv4()} dataField={dayOfWeek} dataFormat={this.statusFormatter} formatExtraData={elem}>{elem}</TableHeaderColumn>;
          })}
        </BootstrapTable>
      </div>
    );
  }
}
