import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddCredit from './AddCredit';

import { getCredits } from '../../DataProvider/Business';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    color: theme.palette.common.white,
    backgroundColor: '#aba4a4',
    textAlign: 'center'
  }
});

class UserHistory extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      limit: 10,
      skip: 0,
      total: 0,
      data: {},
      summary: { credit_plan: '', available_credit: '' }
    };
  }

  async componentWillMount () {
    this.setCredit();
  }

  setCredit = async () => {
    const params = {
      limit: this.state.limit,
      skip: this.state.skip,
      id: this.props.id
    };
    const credit = await getCredits(params);
    if (credit.data.length > 0) {
      this.setState({ summary: {
        credit_plan: credit.data[0].plan,
        available_credit: credit.data[0].available_credits
      } });
    }
    this.setState({
      rows: credit.data,
      total: credit.total,
      skip: credit.skip,
      limit: credit.limit
    });
  }

  handleChangePage = async (event, page) => {
    const params = {
      limit: this.state.limit,
      skip: this.state.rowsPerPage * (page),
      id: this.props.id
    };
    const credit = await getCredits(params);
    this.setState({
      rows: credit.data,
      total: credit.total,
      skip: credit.skip,
      limit: credit.limit,
      page: page
    });
  };

  handleChangeRowsPerPage = async event => {
    const params = {
      limit: event.target.value,
      skip: 0,
      id: this.props.id
    };
    const credit = await getCredits(params);
    this.setState({
      rows: credit.data,
      total: credit.total,
      skip: credit.skip,
      limit: credit.limit,
      page: 0,
      rowsPerPage: event.target.value
    });
  };

  render () {
    const { classes } = this.props;
    const { rows, rowsPerPage, page, total, summary } = this.state;
    function createData (key, value) {
      return { key, value };
    }

    const credits = [
      createData('Credit plan', `${summary.credit_plan} credit`),
      createData('Credit available', `${summary.available_credit} credit`)
    ];
    return (
      <div>
        <br />
        <Grid container direction='row' justify='center'>
          <Grid container item xs={6}>
            <Paper className={classes.root}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header} colSpan={2}>
                      SUMMARY
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credits.map(item => (
                    <TableRow key={item.key}>
                      <TableCell align='center'>{item.key}</TableCell>
                      <TableCell align='center'>{item.value}</TableCell>
                    </TableRow>
                  )
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid container item xs={3}>
            <AddCredit {...this.props} setCredit={this.setCredit} />
          </Grid>
        </Grid>
        <br />
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Date</TableCell>
                  <TableCell align='left'>Transaction</TableCell>
                  <TableCell align='left'>Chargable credit</TableCell>
                  <TableCell align='left'>Credits</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { rows.length > 0 && rows.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell align='left'>{row.created_at}</TableCell>
                    <TableCell align='left'>{row.action}</TableCell>
                    <TableCell align='left' style={{paddingLeft:'110px'}}>{row.chargable_amount}</TableCell>
                    <TableCell align='left' style={{paddingLeft:'66px'}}>{row.available_credits}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              { rows.length > 0 &&
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        'aria-label': 'Prev',
                      }}
                      nextIconButtonProps={{
                        'aria-label': 'Next',
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              }
            </Table>
          </div>
        </Paper>
      </div>
    );
  }
}

UserHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string
};

export default withStyles(styles)(UserHistory);
