
import React from 'react';
import {
  TextInput,
  SelectInput,
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  DeleteButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from '../../Users/component/CancelButton';
import '../../Users/component/Styles/userStyle.css';
const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

function FormToolbar (props) {
  return (
    <Toolbar {...props} >
      <SaveButton />
      <DeleteButton undoable={false} style={{ position: 'absolute', right: '20px' }} />
    </Toolbar>
  );
};
const role = process.env.REACT_APP_EDITOR_USER_ROLE;
const type = process.env.REACT_APP_EDITOR_USER_TYPE;
// eslint-disable-next-line no-eval
const roleChoices = eval(role);
const roleArray = [];
roleChoices.forEach((elem, i) => {
  const obj = {
    id:i,
    name:elem
  };
  roleArray.push(obj);
});
// eslint-disable-next-line no-eval
const typeChoices = eval(type);
const typeArray = [];
typeChoices.forEach((elem, i) => {
  const obj = {
    id:i,
    name:elem
  };
  typeArray.push(obj);
});

const EditEditorUserTab = withStyles(styles)(({ classes, ...props }) =>
  <Edit title='Update' actions={<CancelButton />} {...props} >
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput source='_id' formClassName={classes.inlineBlock} style={{ fontStyle: 'italic' }} readonly disabled />
      <TextInput label='Name' source='nicename' formClassName={classes.inlineBlock} />
      <TextInput label='Surname' source='surName' formClassName={classes.inlineBlock} />
      <TextInput label='Email' source='email' formClassName={classes.inlineBlock} />
      <TextInput label='Password' source='passwordText' formClassName={classes.inlineBlock} />
      <SelectInput label='Role' source='role' choices={roleArray || []} optionValue='name' optionText='name' formClassName={classes.inlineBlock} />
      <SelectInput label='Type' source='type' choices={typeArray || []} optionValue='name' optionText='name' formClassName={classes.inlineBlock} />
    </SimpleForm>
  </Edit>
);

export default EditEditorUserTab;
