Object.defineProperty(exports, '__esModule', {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactAdmin = require('react-admin');

var _jwtDecode = require('jwt-decode');

var _jwtDecode2 = _interopRequireDefault(_jwtDecode);

function _interopRequireDefault (obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty (obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

exports.default = function (client) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (type, params) {
    var _extends2;

    var _Object$assign = Object.assign({}, {
      storageKey: 'token',
      authenticate: { type: 'local' },
      permissionsKey: 'permissions',
      permissionsField: 'roles',
      passwordField: 'password',
      usernameField: 'email'
    }, options);

    var storageKey = _Object$assign.storageKey;

    var authenticate = _Object$assign.authenticate;

    var permissionsKey = _Object$assign.permissionsKey;

    var permissionsField = _Object$assign.permissionsField;

    var passwordField = _Object$assign.passwordField;

    var usernameField = _Object$assign.usernameField;

    switch (type) {
      case _reactAdmin.AUTH_LOGIN:
        var username = params.username;

        var password = params.password;

        return client.authenticate(_extends({}, authenticate, (_extends2 = {}, _defineProperty(_extends2, usernameField, username), _defineProperty(_extends2, passwordField, password), _extends2))); // eslint-disable-line
      case _reactAdmin.AUTH_LOGOUT:
        localStorage.removeItem(permissionsKey);
        return client.logout();
      case _reactAdmin.AUTH_CHECK:
        return localStorage.getItem(storageKey) ? Promise.resolve() : Promise.reject(new Error('Something went wrong with auth token'));
      case _reactAdmin.AUTH_ERROR:
        var code = params.code;

        if (code === 401 || code === 403) {
          localStorage.removeItem(storageKey);
          localStorage.removeItem(permissionsKey);
          return Promise.reject(new Error('Something went wrong with authentication'));
        }
        return Promise.resolve();
      case _reactAdmin.AUTH_GET_PERMISSIONS:
        /*
        JWT token may be providen by oauth,
        so that's why the permissions are decoded here and not in AUTH_LOGIN.
        */
        // Get the permissions from localstorage if any.
        var permissions = JSON.parse(localStorage.getItem(permissionsKey));
        // If any, provide them.
        if (permissions) {
          return Promise.resolve(permissions);
        } else {
          try {
            var jtwToken = localStorage.getItem(storageKey);
            var decodedToken = (0, _jwtDecode2.default)(jtwToken); // eslint-disable-line
            var _permissions = decodedToken[permissionsField] ? decodedToken[permissionsField] : [];
            localStorage.setItem(permissionsKey, JSON.stringify(_permissions));
            return Promise.resolve(_permissions);
          } catch (e) {
            return Promise.reject(e);
          }
        }

      default:
        return Promise.reject(new Error('Unsupported FeathersJS authClient action type'));
    }
  };
};

module.exports = exports['default'];
