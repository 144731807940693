import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

export const getHistory = async params => {
  let url = `${process.env.REACT_APP_API_BASE_URL}/events?$limit=${params.limit}&$skip=${params.skip}`;
  if (typeof params['user_id'] !== 'undefined') {
    url = `${url}&user_id=${params.user_id}`;
  }

  if (typeof params['event'] !== 'undefined') {
    url = `${url}&event=${params.event}&action=fetchAllEvent`;
  }

  if (
    typeof params['version_id'] !== 'undefined' &&
  params['version_id'] !== ''
  ) {
    url = `${url}&version_id=${params.version_id}`;
  }

  const token = localStorage.getItem('token');
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        cert: 'admin'
      }
    })
    .then(response => {
      if (typeof response.data !== 'undefined') {
        if (!_.has(response.data, 'data') && response.data.length > 0) {
          const data = Object.assign({}, response.data);
          response.data = {
            data,
            limit: data.length,
            total: data.length,
            skip: 0
          };
        }
        response.data.data = _.map(response.data.data, function (data) {
          let metadata = [];
          if (data.metadata !== null) {
            if (Array.isArray(data.metadata)) {
              const metadataSize = data.metadata.length;
              data.metadata.map((data, index) => {
                _.forEach(data, (value, key) => {
                  if (value !== '') {
                    let data = {};
                    if (key === 'expiry_date') {
                      value = moment(value).format('YYYY-MM-DD H:mm:ss');
                    }
                    key = _.upperFirst(_.join(_.split(key, '_'), ' '));
                    data[key] = value;
                    metadata.push(data);
                  }
                });
                // eslint-disable-next-line standard/object-curly-even-spacing
                if (metadataSize - 1 !== index) metadata.push({ newline: true });

                return true;
              });
            } else {
              _.forEach(data.metadata, (value, key) => {
                if (value !== '') {
                  let data = {};
                  if (key === 'expiry_date') {
                    value = moment(value).format('YYYY-MM-DD H:mm:ss');
                  }
                  key = _.upperFirst(_.join(_.split(key, '_'), ' '));
                  data[key] = value;
                  metadata.push(data);
                }
              });
            }
          }
          const name = _.upperFirst(_.join(_.split(data.name, '_'), ' '));
          return {
            name: name,
            description: data.description,
            user_id: data.user_id,
            nicename: data.nicename,
            email: data.email,
            metadata: metadata,
            os_type: data.os_type,
            date: moment(data.date).format('YYYY-MM-DD H:mm:ss')
          };
        });
        return response.data;
      }
    })
    .catch(() => {});
};
