import React from 'react';
import { List, Datagrid, TextField, DateField, UrlField } from 'react-admin';

const NewsLetterStatus = (props, i) => (
  <List title='Status' {...props} bulkActionButtons={false}>
    <Datagrid key={i}>
      <TextField source='id' />
      <DateField source='publishedAt' label='Published At' sortable />
      <TextField source='edition' label='Edition' sortable />
      <TextField source='status' label='Status' sortable />
      <TextField source='title' label='Title' sortable={false} />
      <UrlField source='url' />
    </Datagrid>
  </List>
);

export default NewsLetterStatus;
