import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

class CouponEdit extends Component {
  render () {
    return <CouponForm {...this.props} />;
  }
}

export default CouponEdit;

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const CouponForm = withStyles(styles)(({ classes, ...props }) => (
  <Edit title='Vodafone' {...props}>
    <SimpleForm>
      <TextInput label='Name' source='name' formClassName={classes.inlineBlock} />
      <TextInput label='Prefix' source='prefix' formClassName={classes.inlineBlock} />
      <TextInput label='Description' source='short_description' formClassName={classes.inlineBlock} />
      <SelectInput source='validity' choices={[ { id: '1', name: '1' }, { id: '2', name: '2' }, { id: '3', name: '3' }, { id: '4', name: '4' }, { id: '5', name: '5' }, { id: '6', name: '6' }, { id: '7', name: '7' }, { id: '8', name: '8' }, { id: '9', name: '9' }, { id: '10', name: '10' }, { id: '11', name: '11' }, { id: '12', name: '12' } ]} formClassName={classes.inlineBlock} optionValue='id' optionText='name' />
      <BooleanInput label='Status' source='status' />
    </SimpleForm>
  </Edit>
));
