import React, { Component, Fragment } from 'react';
import {
  Tabs,
  Tab,
  Divider,
  Button,
  withStyles 
} from '@material-ui/core';
import {SimpleForm, TextInput, Toolbar, useNotify } from 'react-admin'
import _ from 'lodash';
import CorporateUser from './CorporateUser';
import UserHistory from '../../Users/component/UserHistory';
import { getCorporateUserInformation, addCorporateUsers, getCorporateInfo } from '../../DataProvider/Corporate';
import EditCorporateInfo from './EditCorporateInfo';

const styles = {
  textInputBlock: { display: 'inline-flex', marginRight: '1rem'},
  Block: {display: 'block !important'},
  widthList: {width: '800px'},
  formFields: {
    position: 'relative', // Ensure the container is relative for absolute positioning
  },
  buttonRow: {
    position: 'absolute',
    bottom: 20,
    padding: '1rem', // Add padding for better visibility
    backgroundColor: '#f5f5f5', // Example background color
    borderTop: '1px solid #ddd', // Example border for separation from form fields
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '50px',
    borderRadius: '4px',
  },
  button1: {
    left: 16,
  },
  button2: {
    left: 286
  },
  mb60: {
    marginBottom: '60px'
  }
};

const FormToolbar = (props) => {
  const {handleBack, handleSave} = props;
  const notify = useNotify();

  const save = async () => {
    const {existingUserCount, newUserCount, userErrorCount, invalidEmailCount} = await handleSave();

    if(newUserCount) notify(`${newUserCount} User added successfully`, { type: 'success' });
    if(existingUserCount) notify(`${existingUserCount} User Found already existing`, { type: 'info' });
    if(userErrorCount) notify(`Error with ${userErrorCount} users`, { type: 'danger' });
    if(invalidEmailCount) notify(`${invalidEmailCount} invalid emails were found`, { type: 'danger' });
  }

  return (
    <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>          
      <Button onClick={handleBack}>Back</Button>
      <Button onClick={save}>Save corporate client</Button>
    </Toolbar>
  )
}
class CorporateEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: 0,
      showEvent: false,
      corpClientName: '',
      corporateUsers: {},
      addCorporateUsers: false,
      numberOfInputRows: 1,
      addRecipientList: false,
      inputValues: [{ first_name: '', surname: '', email: '' }],
      csvInput: '',
      isActiveCorporate: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getUserData = async (corporateUser) => {
    this.setState({ corporateUsers : corporateUser });
  }

  handleInputChange = (index, field, value) => {
    this.setState((prevState) => {
      const inputValues = [...prevState.inputValues];
      inputValues[index][field] = value;
      return { inputValues };
    });
  };

  addInputRow = () => {
    this.setState((prevState) => ({
      numberOfInputRows: prevState.numberOfInputRows + 1,
      addRecipientList: false,
      inputValues: [...prevState.inputValues, { first_name: '', surname: '', email: '' }],
    }));
  };

  decodeURLFragment = () => {
    const urlFragment = window.location.href.split('corporate-edition/')[1];
    const decodedFragment = decodeURIComponent(urlFragment);
    const numbers = decodedFragment.split('/').map(Number);

    return numbers;
  };

validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

  handleSave = async (props) => {
    const [corporateId, versionId] = this.decodeURLFragment();
    const data = {action: "saveCorporateUser", user: []};
    const invalidEmails = [];
    let result;
    if(this.state.addRecipientList) {
      const emailArray = this.state.csvInput.split(',');
      for(const email of emailArray) {
        if(this.validateEmail(email.trim())) {
          data.user.push({email: email.trim(), first_name: "", surname: "",
           version_id: versionId, corporate_id: corporateId })
        } else {
          invalidEmails.push(email);
        }
      }
    } else {
      for(const inputObject of this.state.inputValues) {
        if(this.validateEmail(inputObject.email.trim())) {
          data.user.push({...inputObject, version_id: versionId, corporate_id: corporateId});
        } else {
          invalidEmails.push(inputObject.email);
        }
      }
    }

    result = await addCorporateUsers(data);

    if(invalidEmails.length) console.log("invalidEmails",invalidEmails);

    return {...result, invalidEmailCount: invalidEmails.length}
  };

  async UNSAFE_componentWillMount () {
    try {
      const url = window.location.href;
      const parts = url.split('/');
      const lastPart = parts[parts.length - 1];
      const id = lastPart.split('%')[0];
      const corporateDetails = await getCorporateInfo(id);
      this.setState({corpClientName: corporateDetails.name});
      corporateDetails && this.setState({isActiveCorporate: Number(corporateDetails.status)})
      let corporateUser = await getCorporateUserInformation(this.props.id);
      await this.getUserData(corporateUser);
      const slug = ['amplifon_emea', 'amplifon_usa', 'amplifon_apac', 'amplifon_ita'];
      if (_.has(corporateUser, 'data')) {
        corporateUser = corporateUser.data;
        if (_.has(slug.indexOf(corporateUser[0], 'version_slug')) && slug.indexOf(corporateUser[0].version_slug) > -1) {
          this.setState({ showEvent: true });
        }
      }
    } catch (error) {
      console.log('[CorporateEdit][willmount] ======= ', error);
    }
  }

  handleAddUser = () => {
    this.setState({addCorporateUsers : true})
  }

  handleBack = () => {
    this.setState({addCorporateUsers : false})
  }

  inputRow = (classes, index) => {
    return (
    <>
      <TextInput label='Name' source={`nicename_${index}`}
        value={this.state.inputValues[index].first_name}
        onChange={(e) => this.handleInputChange(index, 'first_name', e.target.value)}
        className = { 
        index === this.state.numberOfInputRows - 1
        ? [classes.textInputBlock, classes.mb60]
        : classes.textInputBlock } />
        
        <TextInput label='Surname' source={`surName_${index}`}
        value={this.state.inputValues[index].surname}
        onChange={(e) => this.handleInputChange(index, 'surname', e.target.value)}
        className = { 
        index === this.state.numberOfInputRows - 1
        ? [classes.textInputBlock, classes.mb60]
        : classes.textInputBlock } />

        <TextInput lable='Email' source={`email_${index}`}
        value={this.state.inputValues[index].email}
        onChange={(e) => this.handleInputChange(index, 'email', e.target.value)}
        className = { 
        index === this.state.numberOfInputRows - 1
        ? [classes.textInputBlock, classes.mb60]
        : classes.textInputBlock } />
    </>
    )
  }


  render () {
    const { value, showEvent, corporateUsers,isActiveCorporate } = this.state;
    const {classes} = this.props;

    const inputRows = Array.from({ length: this.state.numberOfInputRows }, (_, index) => (
      <Fragment key={index}>
        {this.inputRow(classes, index)}
      </Fragment>
    ));

    return !this.state.addCorporateUsers ? (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Tabs
            value={value}
            indicatorColor='primary'
            onChange={this.handleChange}
          >
            <Tab label='Edit Corporate' style={{ fontWeight: 'bold' }} />
            {isActiveCorporate && <Tab label='Users' style={{ fontWeight: 'bold' }} />}
            {showEvent && <Tab label='Events' style={{ fontWeight: 'bold' }} />}
          </Tabs>
          </div>
          {value !== 0 && <div style={{ marginLeft: 'auto' }}>
            <Button style={{ background: '#868E96', color: 'white', fontWeight: '700', fontSize: '14px', marginBottom:"8px" }} 
            onClick={this.handleAddUser}> Add Users </Button>
          </div>}

        <Divider />
        {value === 0 && <EditCorporateInfo />}
        {value === 1 && <CorporateUser {...this.props} corpName={this.state.corpClientName}/>}
        {value === 2 && <UserHistory users={corporateUsers} event={'sync_user'} />}
      </Fragment>

    ) :
    (
      <SimpleForm toolbar={<FormToolbar handleBack={this.handleBack} handleSave={this.handleSave}/>} className={classes.formFields}>
        {this.state.addRecipientList ? 
        <TextInput multiline source="Recipients"
          value={this.state.csvInput}
          onChange={(event) => {this.setState({csvInput: event.target.value})}}
          className={[classes.mb60, classes.widthList]} /> :
        inputRows}
        <Button className={[classes.buttonRow, classes.button1]} onClick={this.addInputRow}>Add Other Recipients</Button>
        <Button className={[classes.buttonRow, classes.button2]} onClick={() => this.setState({addRecipientList: true})}>Add List of Recipients</Button>
      </SimpleForm>
    )
  }
}

export default withStyles(styles)(CorporateEdit);
