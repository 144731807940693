import React from 'react';
import {

 TextInput,
 Edit,
 SimpleForm,
 SaveButton,
 Toolbar,
 DeleteWithConfirmButton,
 SelectInput

} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import CancelButton from './CancelButton';
import { provinceOptions } from '../../../lib/config';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton
      undoable={false}
      style={{ position: 'absolute', right: '20px' }}
    />
  </Toolbar>
);

const validateFiscalCode = (value) => {
  const regexPattern = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;

  if (!value) {
    return 'Fiscal code is required';
  }

  if (!regexPattern.test(value)) {
    return 'Invalid fiscal code format';
  }

  return undefined; // Validation passed
};

const OrganisationInfo = withStyles(styles)(({ classes, ...props }) => (
 <Edit title="Update" actions={<CancelButton />} {...props}>
  <SimpleForm toolbar={<FormToolbar />}>
   <TextInput
    source="id"
    formClassName={classes.inlineBlock}
    style={{ fontStyle: 'italic' }}
    readonly
    disabled
   />
   <TextInput label="Name" source="name" formClassName={classes.inlineBlock} />
   <TextInput
    label="Contact"
    source="contact"
    formClassName={classes.inlineBlock}
   />
   <TextInput
    label="Address Line 1"
    source="address_1"
    formClassName={classes.inlineBlock}
   />
   <TextInput
    label="Address Line 2"
    source="address_2"
    formClassName={classes.inlineBlock}
   />
   <TextInput
    label="Postal Code"
    source="post_code"
    formClassName={classes.inlineBlock}
   />
   <TextInput label="City" source="city" formClassName={classes.inlineBlock} />
   <TextInput
    source="vat_number"
    formClassName={classes.inlineBlock}
   />
   <TextInput
    source="fiscal_code"
    formClassName={classes.inlineBlock}
    validate={validateFiscalCode}
   />
   <TextInput
    source="recipient_code"
    formClassName={classes.inlineBlock}
    readonly
    disabled
   />

   <SelectInput
    source="province"
    choices={provinceOptions}
    formClassName={classes.inlineBlock}
    optionValue="id"
    optionText="name"
   />
  </SimpleForm>
 </Edit>
));

export default OrganisationInfo;
