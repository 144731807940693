import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  EditButton
} from 'react-admin';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

export const CustomFunction = (props) =>{
  const [copied, setCopied] = useState(false);
  const onClick = async () => {
    const widgetId = props.record.widget_id;
    const element =`<div id='gmi-registration-widget' data-widgetid='${widgetId}'></div>`;
    const url =`<script src='${props.record.url}'></script>`;
    const copiedText = `${url}\n\n\n${element}`;
    await navigator.clipboard.writeText(copiedText);
    setCopied({copied : true});
  }
  return (copied ? <DoneIcon style={{color:'green'}}/> : <ContentCopyIcon onClick={(e)=> onClick(e)}/>)

}
class Widget extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return (
      <List
        {...this.props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
      >
        <Datagrid key={''}>
        <TextField source='srNo' label="Sr No" sortable={false} /> 
          <TextField source='widget_id' label="Widget Id" sortable={false} />
          <TextField label='Widget Name' source='widget_name' sortable={false}/>
          <TextField source="host_name" label="Host Name" sortable={false} />
          <CustomFunction source="url" label="Url Name" />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}


export default Widget;
