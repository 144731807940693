import React, { Component } from 'react';
import { Filter, TextInput, AutocompleteInput } from 'react-admin';

class GiftsFilter extends Component {

  render () {
    return (
      <Filter {...this.props}>
        <TextInput label='Search' source='q' alwaysOn />
        <AutocompleteInput label='Select Column' source='select_column' choices={[
          { id: 'gift', name: 'Gift' },
          { id: 'senderName', name: 'Sender Name' },
          { id: 'receiverName', name: 'Receiver Name' },
          { id: 'senderEmail', name: 'Sender Email' },
          { id: 'receiverEmail', name: 'Receiver Email' },
          { id: 'giftId', name: 'Gift Id' },
        ]} />
      </Filter>
    );
  }
}

export default GiftsFilter;
