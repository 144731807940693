import React from 'react';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  AutocompleteInput,
  DateInput,
  BooleanInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import CancelButton from './CancelButton';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const UserSubscriptionTab = withStyles(styles)(({ classes, ...props }) =>
  <Edit title='Update' actions={<CancelButton />} {...props} >
    <SimpleForm toolbar={<FormToolbar />}>
      <AutocompleteInput label='Subscription Type' source='subscription_type' formClassName={classes.inlineBlock} choices={[
        { id: 'trial', name: 'Trial' },
        { id: 'month', name: 'Month' },
        { id: 'lifetime', name: 'Lifetime' },
        { id: 'year', name: 'Year' },
        { id: 'expired', name: 'Expired' },
        { id: 'partner', name: 'Partner' },
        { id: 'corporate', name: 'Corporate' }
      ]} />
      <DateInput label='Subscription Expiry' source='subscription_expiration_date_formatted' formClassName={classes.inlineBlock} />
      <AutocompleteInput label='Subscription Plan' source='subscription_plan' formClassName={classes.inlineBlock} choices={[
        { id: 'personal', name: 'Personal' },
        { id: 'gift', name: 'Gift' },
        { id: 'promotion', name: 'Promotion' },
        { id: 'coupon', name: 'Coupon' },
      ]} />
      <BooleanInput label='Mailchimp Status' source='mailChimpStatus' formClassName={classes.inlineBlock} />
    </SimpleForm>
  </Edit>
);

export default UserSubscriptionTab;
