import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { updateUsersContent } from '../../DataProvider/EditionConfiguration.js';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';
import './EditionJournalist.css';
import _ from 'lodash'
let listOfUsers;
export default class EditionJournalist extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      list: [],
      data: [],
      headerDates:[],
      clicked:false,
    };
  }
  statusFormatter = (cell, row, dataFormatter) => {
    return (<div className='checkboxes'><input type='checkbox' className='checkbox' defaultChecked={cell} onClick={(e) => this.changeOnClick(e, row, dataFormatter, this.props.list, this.stateUpdateFunction, this.handleAlert)} /></div>);
  }
  imageFormatter (cell, row) {
    return (<label className='journalist-name' src={cell}>{cell}</label>);
  }
  changeOnClick = async (e, row, dataFormatter, list, statusDisplayFunction, handleAlert) => {
    const selectedElement = e.target;
    const checked = e.target.checked;
    const id = row._id;
    let assignedVersion = [];
    const listOfVersion = list;

    const res = await handleAlert(dataFormatter, checked, row.nicename);
    if (res) {
      selectedElement.checked = !!checked;
      row[dataFormatter] = selectedElement.checked;
      for (const key in row) {
        if (row[key] === true) {
          const dataList = listOfVersion && listOfVersion.map(elem => elem.version.slug);
          if (dataList.includes(key)) {
            assignedVersion.push(key);
          }
        }
      }
      const updatedMetaData = {
        meta:{
          versions:assignedVersion
        }
      };
      const response = await updateUsersContent(updatedMetaData, id);
      const list = [];
      if (response && response.status === 200) {
        this.state.data.forEach(elem => {
          if (row.name === elem.name) {
            list.push(row);
          } else {
            list.push(elem);
          }
        });
        // We are updating state without rerendering so that the page does not reset its position.
        this.state.data = list;
        statusDisplayFunction(updatedMetaData, id);
      }
    } else {
      selectedElement.checked = row[dataFormatter];
    }
  };
  handleAlert = (dataFormatter, checked, name) =>
    new Promise((resolve, reject) => {
      swal({
        title: 'Are you sure?',
        text: `You ${!checked ? "don't" : ''} want to assign ${dataFormatter} edition to ${name}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      })
        .then((value) => {
          resolve(value);
        });
    });
  stateUpdateFunction =(updatedMetaData, id) => {
    let updatedList = [];
    listOfUsers.forEach(elem => {
      if (elem._id === id) {
        elem['meta'] = updatedMetaData.meta;
      }
      updatedList.push(elem);
    });
    this.props.updateUserFunction(updatedList, false);
  }

  async componentDidMount () {
    const conatiner = document.getElementById('container-body');
    conatiner.style.height = `600px`;

    listOfUsers = this.props.users || [];
    const listOfEdition = this.props.list.filter(item => !_.isEmpty(_.get(item, 'version', {}).name) && _.get(item, 'version.name', '').indexOf('Partner') === -1);
    listOfUsers.length && listOfUsers.forEach(elem => {
      const versionList = elem.meta ? elem.meta.versions : [];
      let obj = {};
      for (let index = 0; index < listOfEdition.length; index++) {
        const versionSlug = listOfEdition[index].version.slug;
        obj = {
          ...obj,
          [versionSlug]:!!versionList.includes(versionSlug),
        };
      }
      this.state.data.push({
        ...elem,
        ...obj,
        journalistName:elem.nicename
      });
    }
    );
    this.setState({ headerDates: listOfEdition });
  }
  render () {
    const { headerDates } = this.state;
    return (
      <div id='container-body'>
        <div>
          <BootstrapTable
            data={this.state.data}
            tableContainerClass='edition-journalist'
            className='container-fluid mt-2 journalist-container'
          >
            <TableHeaderColumn
              tdStyle={{
                textAlign:'left',
                fontSize:'16px',
                position: 'sticky',
                left:'-0.5px',
                backgroundColor:'#DBDBDB',
                borderBottom:'none',
                boxShadow: '4px 0px 4px 0px rgb(202 202 202 / 75%)',
                padding: '17px 10px'
              }}
              thStyle={{
                position: 'sticky',
                left:'-0.5px',
                paddingLeft: '10px',
                backgroundColor: '#DBDBDB',
              }}

              dataFormat={this.imageFormatter} dataField='journalistName' isKey>Journalist</TableHeaderColumn>
            {headerDates.map((elem, i) => {
              return <TableHeaderColumn thStyle={{ textAlign:'center', paddingBottom:'15px' }} tdStyle={{ textAlign:'center', width: '9vw'
              }} key={uuidv4()} dataField={elem.version.slug} dataFormat={this.statusFormatter} formatExtraData={elem.version.slug} width='12vw'><div>&nbsp;&nbsp;<br /><span className='text-container'>{elem.corporate.name}&nbsp;<br />({elem.version.name})</span></div></TableHeaderColumn>;
            })}
          </BootstrapTable>
        </div>
      </div>
    );
  }
}
