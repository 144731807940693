import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  Pagination
} from 'react-admin';
import Filter from './CouponFilters';

class Coupon extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  render () {
    return (
      <List
        {...this.props}
        exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
      >
        <Datagrid key={'12'}>
          <TextField source='iD' label="Id" sortable={false} />
          <TextField source="name" label="Name" sortable />
          <TextField source="short_description" label="Description" />
          <TextField source="prefix" label="Campaign Code" sortable/>
          <TextField source="validity" label="Validity(months)" />
          <TextField source="total_coupon" label="Coupons" sortable={false} />
          <TextField source="redeemed_coupon" label="Redemeed" sortable={false} />
          <TextField source="redeemed_rate" label="Redemption Rate" sortable={false} />
          <TextField source="expired_coupon_subscription" label="Expired" sortable={false} />
          <TextField source="converted_user" label="Subscribed" />
          <TextField source="status" label="Status" sortable />
          <ShowButton label="Code" />
          <EditButton label="Edit" />
        </Datagrid>
      </List>
    );
  }
}

export default Coupon;
