import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import getNewsMetrics from '../../DataProvider/Newsmetric';
export default class Newsmetrics extends Component {
  constructor (props) {
    super(props);
    this.state = {
      series: [],
      tags: {
        series: [],
      },
      options: {
        colors: ['#fdbb84', '#fee8c8', '#9ebcda', '#99d8c9', '#c994c7', '#1c9099', '#cc9900', '#ffcccc'],
        chart: {
          id: 'bar',
          stacked: true,
          stackType: '100%',
          height: '200px',
          style:{
            width:'10px'
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '50%',
            dataLabels: {
              position: 'center', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: '10px',
            colors: ['#304758'],
            width: '5px'
          }
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: 'bottom',
          offsetX: -20,
          offsetY: 0
        },
        tooltip: {
          followCursor: true,
          inverseOrder: true
        }
      }
    };
  }

  async componentDidMount () {
    const response = await getNewsMetrics();
    let imps = {};
    let tags = {};
    const date = [];
    for (let item in response) {
      let items = response[item];
      if (!_.has(tags, items)) {
        let tagsItems = items.tags;
        for (let tagItem in tagsItems) {
          if (!_.has(tags, tagItem)) {
            tags[tagItem] = {
              name: tagItem, data: [tagsItems[tagItem]]
            };
          } else {
            tags[tagItem].data.push(tagsItems[tagItem]);
          }
        }
      }
    }

    for (let item in response) {
      let impItems = response[item].importance;
      for (let impItem in impItems) {
        if (!_.has(imps, impItem)) {
          imps[impItem] = {
            name: impItem, data: [impItems[impItem]]
          };
        } else {
          imps[impItem].data.push(impItems[impItem]);
        }
      }
    }

    const impsArray = Object.values(imps);
    const tagsArray = Object.values(tags);
    let d = response.map((item) => item.date).filter((item, index, array) => array.indexOf(item) === index);
    date.push(d);
    this.setState({
      options: {

        xaxis: {
          categories: date[0]
        }
      },
      series: impsArray,

      tags: {
        series: tagsArray,
      }
    });
  }
  render () {
    return (
      <div className='app'>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type='bar'
          width='800'
        />
        <Chart
          options={this.state.options}
          series={this.state.tags.series}
          type='bar'
          width='800'
        />
      </div>

    );
  }
}
