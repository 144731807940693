import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import _ from 'lodash';
import slugify from 'slugify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Select, MenuItem, Modal, withStyles } from '@material-ui/core';
import { sortingAdminUsers, updateUserRole } from '../../DataProvider/Corporate';
import { getCorporateUserInformation, updateCorporateUserStatus, updateNewsletterStatus } from '../../DataProvider/Corporate';


function getModalStyle () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '20px 24px 16px 24px'
  },
  modalCancel: {
    marginRight: '10px',
    color: '#010203'
  },
  modalButton : {
    fontFamily: "Suisse Int'l",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid var(--global-01-primary, #010203)',
  },
  modalConfirm: {
    background: '#010203',
    color: '#fff'
  },
  modalHeading: {
    color: '#010203',
    fontFamily: "Suisse Int'l",
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
    margin: '0px' 
  }
});
class CorporateUser extends Component {
  constructor () {
    super();
    this.state = {
      corporateUser: [],
      corporateName: '',
      loading: true,
      open: false,
      modalStyle: getModalStyle(),
      selectedRow: null,
      selectedUserIds: []
    };
  }

  


  body = () => {
    // eslint-disable-next-line react/prop-types
    const { classes,corpName } = this.props;
    let userId, email, role, firstName;
    if(this.state.selectedRow) {
      ({userId, email, role, firstName} = this.state.selectedRow);
    }
    const action = role === 'Administrator'? 'Add' : 'Remove'

    return (
      <>
        {this.state.loading ?
         <div style={this.state.modalStyle} className={classes.paper}><div style={{ textAlign: 'center' }}><CircularProgress /></div> </div> :
         <div style={this.state.modalStyle} className={classes.paper}>
          <h5 className={classes.modalHeading}>
           {action} Administrator
          </h5>
          <p>
            Are you sure you want to {action} {email} as an administrator of {this.state.corporateName}
          </p>
          <div className='d-flex' style={{ textAlign: 'right' }}>
            <Button
              className={[classes.modalCancel, classes.modalButton]}
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            <Button
              className={[classes.modalConfirm, classes.modalButton]}
              onClick={() => {
                updateUserRole({userId : userId, role: role, email: email, firstName, corpName }).then((response) => {
                  if(response.status === 'success') window.location.reload()
                })
              }}
            >
              Confirm
            </Button>
          </div>
        </div>}
 
      </>
    );
  };

  async componentDidMount () {
    let corporateUser = await getCorporateUserInformation(this.props.id);
    if (_.has(corporateUser, 'data') && corporateUser.data.length) {
      corporateUser = corporateUser.data;
      corporateUser = sortingAdminUsers(corporateUser)
      localStorage.setItem('corporateUser', JSON.stringify(corporateUser));
      this.setState({ corporateUser, corporateName: corporateUser[0].corporate_name + ' ' + corporateUser[0].version_name, loading: !this.state.loading });
    } else {
      this.setState({ loading: !this.state.loading });
    }
  }

  statusRenderer = (value, tableMeta, updateValue) => {
    if (!value) return;
    if (value === 'blocked') {
      return <div><span style={{ marginRight: '10px' }}><strong>BLOCK</strong></span><span><Button variant='contained' color='primary' onClick={() => this.updateStatus(tableMeta.rowData[3], 'active', 'updateCorporateUserStatus')}>
        Active
      </Button></span></div>;
    }
    return value.toUpperCase();
  }

  newsletterStatusRenderer = (value, tableMeta, updateValue) => {
    let checked = value === 'subscribed';
    return <Switch checked={checked} id={tableMeta.rowData[0]} onChange={event => this.handleNewsletterStatus(event, updateValue, tableMeta.rowData)} name={value} />;
  }

  updateStatus = async (email, status, action) => {
    const updateUserStatus = await updateCorporateUserStatus(email, status, action);
    if (updateUserStatus && updateUserStatus.updateStatus[1]) {
      this.setState({ loading: !this.state.loading });
      let corporateUser = await getCorporateUserInformation(this.props.id);
      if (_.has(corporateUser, 'data')) {
        corporateUser = corporateUser.data;
        this.setState({ corporateUser, loading: !this.state.loading });
      }
    }
  }

  handleNewsletterStatus = async (e, updateValue, tableData) => {
    const data = { action: 'updateUserStatus' };
    if (_.has(e, ['target', 'checked'])) {
      data['status'] = e.target.checked ? 'subscribed' : 'unsubscribed';
      data['type'] = 'corporate';
      if (tableData) {
        let dataLen = tableData.length;
        data['userId'] = tableData[0];
        data['slug'] = tableData[dataLen - 5];
        data['token'] = tableData[dataLen - 4];
        data['timeStamp'] = tableData[dataLen - 3];
        data['type'] = 'corporate';
        data['cid'] = tableData[dataLen - 2];
        data['vid'] = tableData[dataLen - 1];
      }
      await updateNewsletterStatus(data);
      await updateValue(data.status);
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
      selectedRow: null
    });
  };
 
  openModal = values => {
    this.setState({
      open: true,
      selectedRow: values
    });
  };

  roleDropDownRenderer = (role, rowData) => {
    return (
      <Select
        value={role}
        label="Role"
        onChange={({ target: { value } }) => this.openModal({ userId: rowData.rowData[0],firstName: rowData.rowData[1] || '', email: rowData.rowData[3], role: value })}
      >
        <MenuItem value={'Reader'}>Reader</MenuItem>
        <MenuItem value={'Administrator'}>Administrator</MenuItem>
    </Select>
    )
  }

  render () {

    const options = {
      textLabels: {
        body: {
          noMatch: this.state.loading
            ? <div style={{ textAlign: 'center', marginTop: '10px', marginLeft: '-100px' }}>
              <CircularProgress size={60} thickness={4} />
            </div>
            : 'Sorry, there is no matching data to display',
        },
      },
      filterType: 'checkbox',
      print: 'false',
      downloadOptions: { filename: `CouponFile_${slugify(this.state.corporateName)}.csv` },
      selectableRows: 'none',
      filter: 'false'
    };
    const columns = [
      {
        name: 'user_id',
        label: 'User Id',
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: 'nicename',
        label: 'Name',
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: 'surname',
        label: 'Surname',
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customBodyRender: this.statusRenderer
        }
      },
      {
        name: 'newsletter_active',
        label: 'Newsletter Status',
        options: {
          filter: true,
          sort: true,
          customBodyRender: this.newsletterStatusRenderer
        }
      }, // add new columnn before Subscription Type
      { 
        name: 'role',
        label: 'Role',
        options: {
          filter: false,
          sort: false,
          customBodyRender: this.roleDropDownRenderer
        }
      },
      { name: 'version_slug',
        label: 'Subscription Type',
        options: {
          filter: false,
          sort: false,
          display: false, // this is a hide column
          viewColumns: false
        }
      },
      { name: 'token',
        label: 'Token',
        options: {
          filter: false,
          sort: false,
          display: false, // this is a hide column
          viewColumns: false
        }
      },
      { name: 'timeStamp',
        label: 'timeStamp',
        options: {
          filter: false,
          sort: false,
          display: false, // this is a hide column
          viewColumns: false
        }
      },
      {
        name: 'corporate_id',
        label: 'Cid',
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false
        }
      },
      {
        name: 'version_id',
        label: 'Vid',
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false
        }
      },
    ];
    return (
      <div>
        <MUIDataTable
          title={this.state.corporateName}
          data={this.state.corporateUser}
          columns={columns}
          options={options}
        />
        <Modal
         open={this.state.open}
         onClose={this.handleClose}
         aria-labelledby='simple-modal-title'
         aria-describedby='simple-modal-description'
       >
         <div>{this.body()}</div>
       </Modal>
      </div>
    );
  }
}
CorporateUser.propTypes = {
  id: PropTypes.string
};
export default withStyles(styles)(CorporateUser);
