import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsersIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import CollectionsIcon from '@material-ui/icons/Collections';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { v1 as uuidv1 } from 'uuid';
import CardIcon from './CardIcon';
import styles from './Styles/Users';

class DashboardItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      icon: {}
    };
  }

  componentWillMount () {
    switch (this.props.type) {
      case 'users':
        this.setState({ icon: { name: UsersIcon, color: '#e45f12' } });
        break;
      case 'subscribe':
        this.setState({ icon: { name: CollectionsIcon, color: '#e84040' } });
        break;
      case 'business':
        this.setState({ icon: { name: BusinessIcon, color: '#31708f' } });
        break;
      case 'coupon':
        this.setState({ icon: { name: LoyaltyIcon, color: '#e84040' } });
        break;
      default:
        this.setState({ icon: { name: UsersIcon, color: '#31708f' } });
        break;
    }
  }

  render () {
    return (
      <Grid container   style={styles.item} >
        <Paper style={{ margin: '10px' }} xs={4}>
          <CardIcon
            Icon={this.state.icon.name}
            bgColor={this.state.icon.color} />
          <Table aria-label='customized table'>
            <TableBody>
              { this.props.uiLayout === 'card' && this.props.items.map(item => (
                <TableRow key={uuidv1()}>
                  <TableCell align='center'>{item.label}</TableCell>
                  <TableCell align='center'>{item.value}</TableCell>
                </TableRow>
              ))}
              { this.props.uiLayout === 'table' && this.props.items.map(item => (
                <TableRow key={item.key}>
                  {item.first && <TableCell align='center'>{item.first}</TableCell>}
                  {item.second && <TableCell align='center'>{item.second}</TableCell>}
                  {item.third && <TableCell align='center'>{item.third}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    );
  }
};

DashboardItem.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string,
  uiLayout: PropTypes.string
};

class UsersMain extends Component {
  render () {
    return (
      <Grid container direction='row' justifyContent='space-between'>
        {Object.keys(this.props.data).map((value, key) => {
          let uiLayout = 'card';
          if (value === 'coupon') {
            uiLayout = 'table';
          }
          return (
            <DashboardItem style={styles.rowContainer} type={value} uiLayout={uiLayout} key={uuidv1()} items={this.props.data[value]} />
          );
        })}
      </Grid>
    );
  }
}

export default UsersMain;

UsersMain.propTypes = {
  data: PropTypes.object
};
