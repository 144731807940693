import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const Business = (props, i) => (
  <List title='Business' {...props} bulkActionButtons={false}>
    <Datagrid key={i}>
      <TextField source='name' label='Name' sortable={false} />
      <TextField source='city' label='City' sortable={false} />
      <TextField source='plan' label='Plan' sortable={false} />
      <TextField source='status' label='Status' sortable={false} />
      <TextField source='vat_number' label='Vat number' sortable={false} />
      <TextField source='recipient_code' label='Recipient code' sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default Business;
