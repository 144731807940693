import axios from 'axios';

export const getReferrerOptions = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/referrer`;
  const token = await localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then((response) => {
    if (typeof response.data !== 'undefined') {
      return (response.data.data);
    }
  }).catch(() => {
    console.log('Error while fetching options for Filter');
  });
};
