const opsBackgroundImage = process.env.REACT_APP_OPS_BACKGROUND_IMAGE;

const styles = ({
  main: {
    backgroundImage: `url(${opsBackgroundImage})`
  },
  avatar: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 80,
  },
  icon: { display: 'none' },
});

export default styles;
