import React from 'react';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from './CancelButton';
import { CheckboxGroupInput } from 'react-admin';
import './Styles/ManageRole.css';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);



const ManageRole = withStyles(styles)(({ classes, ...props }) => (
  <Edit title='Update' actions={<CancelButton />} {...props}>
    <SimpleForm toolbar={<FormToolbar />}>
      <CheckboxGroupInput
        source='role'
        className='mycheckbox'
        choices={[
          { id: 'admin', name: 'Administrator' },
          { id: 'editor', name: 'Journalist' },
        ]}
      />
      <CheckboxGroupInput
        source='role'
        className='mycheckbox'
        label=" "
        disabled
        choices={[
          { id: 'business_admin', name: 'Business Administrator' },
          { id: 'business_user', name: 'Business User'},
          { id: 'corporate_admin', name: 'Corporate Administrator' },
          { id: 'corporate_user', name: 'Corporate User' },
        ]}
      />
      
    </SimpleForm>
  </Edit>
));

export default ManageRole;
