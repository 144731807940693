import React, { Component } from 'react';
import { DateInput } from 'react-admin';

class PeriodInput extends Component {
  render () {
    const styles = {
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop:'30px',
        position:'relative',
        top:'20px'
      },
    };

    return (
      <span style={styles.row}>
        <DateInput
          // eslint-disable-next-line react/prop-types
          source={`${this.props.source}_gte`}
          label='Start date'
        />
        &nbsp;
        <DateInput
          // eslint-disable-next-line react/prop-types
          source={`${this.props.source}_lte`}
          label='End date'
        />
      </span>
    );
  }
}

export default PeriodInput;
