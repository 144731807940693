import axios from 'axios';
import _ from 'lodash';
import download from 'downloadjs';

export const getCouponTypes = async (params = {}) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/coupon-types`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then(response => {
    if (_.has(response, ['data', 'data'])) {
      return response.data.data;
    } else {
      return [];
    }
  }).catch(() => {
  });
};

export const getCouponTypeDetails = async (params = {}) => {
  const currentUrl = new URL(window.location.href);
  const couponId = currentUrl.hash.split('/')[2];

  const url = `${process.env.REACT_APP_API_BASE_URL}/coupon-types/${couponId}`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then(response => {
    if (_.has(response, ['data'])) {
      return Promise.resolve(response.data);
    } else {
      return [];
    }
  }).catch(() => {
  });
};

export function getCouponList (id, limit, skip) {
  const url = `${process.env.REACT_APP_API_BASE_URL}/coupons?coupon_type_id=${id}&$limit=${limit}&$skip=${skip}`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      platform: 'ops',
      cert: 'admin'
    }
  }).then(response => {
    if (_.has(response, 'data')) {
      return response.data;
    } else {
      return [];
    }
  }).catch(() => {
  });
};

export function getAllCouponList (id, toggleLoadingState) {
  const url = `${process.env.REACT_APP_API_BASE_URL}/fetch-all-coupons?coupon_type_id=${id}`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      platform: 'ops',
      cert: 'admin',
    },
    responseType:'arraybuffer'
  }).then(response => {
    if (_.has(response, ['data'])) {
      const contentType = response.headers['content-type'];
      download(response.data, `coupons.${contentType.split('/')[1]}`, contentType);

      // calling the function to toggle status of loader
      toggleLoadingState(false);
      return Promise.resolve(response.data);
    } else {
      return [];
    }
  }).catch(() => {
  });
};

export function generateCoupon (id, count) {
  const data = {
    'action': 'generateCoupon',
    id,
    count
  };
  return axios({
    url:`${process.env.REACT_APP_API_BASE_URL}/coupons`,
    headers: { 'authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
      'cert': 'admin' },
    method:'post',
    data,
  }).then(response => {
    if (_.has(response, 'data')) {
      return response.data;
    } else {
      return '';
    }
  }).catch(() => {
  });
};
