const _ = require('lodash');

export function prepareWidgetData(data) {
    const toggleStatus = {
        "true":'active',
        "false":'inactive'
      }
      let widgetMeta = {
        should_name_mount:'inactive',
        should_surname_mount:'inactive',
        should_coupon_mount:'inactive',
      };
      const hasKey =  data.hasOwnProperty('toggle_name') || data.hasOwnProperty('toggle_surname') || data.hasOwnProperty('toggle_coupon');
      if(hasKey){
        if(data.hasOwnProperty('toggle_name')){
          widgetMeta.should_name_mount= toggleStatus[data.toggle_name]
          }
          if(data.hasOwnProperty('toggle_surname')){
            widgetMeta.should_surname_mount= toggleStatus[data.toggle_surname]
            }
            if(data.hasOwnProperty('toggle_coupon')){
              widgetMeta.should_coupon_mount= toggleStatus[data.toggle_coupon]
              }
      }
      const widgetID = data.widget_name + data.host_name;
      const updatedData = {
        widget_name:data.widget_name,
        host_name:data.host_name,
        widget_id:widgetID,
        status:_.has(data,'status') ? toggleStatus[data.status] : 'inactive',
        url:'https://cdn.jsdelivr.net/gh/ketan-kumar/widget@e5456ccc3c3373ae42c971946be74e47a5f2c7e0/test.js',
        widget_meta:JSON.stringify(widgetMeta)
      }
      return updatedData;
}

export function prepareData(response) {
  const widgetMeta = JSON.parse(response.widget_meta);
  const status = {
    "active" : true,
    "inactive" : false
  }
  const data = {
    id: response.id,
    widget_id:response.widget_id,
    widget_name:response.widget_name,
    host_name:response.host_name,
    toggle_name: status[widgetMeta.should_name_mount],
    toggle_surname: status[widgetMeta.should_surname_mount],
    toggle_coupon: status[widgetMeta.should_coupon_mount],
    status:status[response.status],
    url:response.url,
}
return data;
}