import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';
import CustomFooter from './CustomFooter';
import CustomToolbar from './CustomToolbar';
import { getCouponList, getCouponTypeDetails } from '../../DataProvider/Coupon';
let total;
class CouponList extends Component {
  constructor () {
    super();
    this.state = {
      couponData : [],
      couponName : 'coupon',
      loading: true,
      isCsv:false,
      isDownloadingCSV: false
    };
  }

  toggleLoadingState = status => {
    this.setState({ isDownloadingCSV: status });
  };

  async stateUpdateFunction (limit = 10, skip = 0) {
    const couponResp = await getCouponList(this.props.id, limit, skip);
    const couponData = couponResp.data;
    total = couponResp.total;
    const couponDetails = await getCouponTypeDetails();
    const couponName = (!_.isEmpty(couponDetails) && couponDetails.name) || '';

    if (couponData && couponData.length > 0) {
      for (let i = 0; i < couponData.length; i++) {
        if (Number(couponData[i].status) === 1) {
          couponData[i].status = 'Active';
        } else if (Number(couponData[i].status) === 2) {
          couponData[i].status = 'Redeemed';
        };
      }
      this.setState({ couponData, couponName: couponName || 'coupon', loading: !this.state.loading });
    } else this.setState({ loading: !this.state.loading });
  }
  async componentDidMount () {
    await this.stateUpdateFunction();
  }

  renderStatus = (value) => {
    return Number(value) === 1 ? 'Active' : Number(value) === 2 ? 'Redeemed' : '';
  }

  render () {
    const { isDownloadingCSV } = this.state;

    const options = {
      textLabels: {
        body: {
          noMatch: (!this.state.loading
            ? 'Sorry, there is no matching data to display' : <div key={this.state.loading} style={{ textAlign:'center', marginTop:'10px', marginLeft: '-100px' }}>
              <CircularProgress size={60} thickness={4} />
            </div>)

        } },
      customToolbar: () => {
        return (
          <CustomToolbar toggleLoadingState={this.toggleLoadingState} id={(this.props.id)}/>
        );
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        count = total;
        return (
          <CustomFooter
            key={page}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      onTableChange: (action, tableState) => {
        const limit = tableState.rowsPerPage;
        const skip = (tableState.page + 1) * tableState.rowsPerPage - tableState.rowsPerPage;
        if (action === 'changePage' || action === 'changeRowsPerPage') {
          this.stateUpdateFunction(limit, skip);
        }
      },
      filterType: 'checkbox',
      print: 'false',
      selectableRows: 'none',
      download:false,
    };

    const columns = [
      { name: 'name', label: 'Name', options: { filter: false, sort: false } },
      { name: 'code', label: 'Coupon Code', options: { filter: false, sort: false } },
      { name: 'validity', label: 'validity', options: { filter: false, sort: false } },
      { name: 'redeemed_at', label: 'Redemption Date', options: { filter: false, sort: true } },
      { name: 'status', label: 'Status', options: { filter: true, sort: true } }
    ];
    return (
      <div >
        {isDownloadingCSV && <div className='loader-container'><LinearProgress /></div>}
        <MUIDataTable
          title={this.state.couponName}
          data={this.state.couponData}
          columns={columns}
          options={options}
          print={false}
        />
      </div>
    );
  }
}

CouponList.propTypes = {
  id: PropTypes.string
};

export default CouponList;
