import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import './CouponFooter.css';
const defaultFooterStyles = {
  root: {
    '&:last-child': {
      // border:'2px solid red',
      // padding: '0px 0px 0px 800px'
    }
  }
};

class CustomFooter extends React.Component {
  render () {
    const { classes } = this.props;

    return (
      <TableFooter className='coupon-footer'>
        <TableRow >
          <TableCell className='coupon-row'>
            <TablePagination
              className={classes.root}
              count={this.props.count}
              page={this.props.page}
              rowsPerPage={this.props.rowsPerPage}
              onChangePage={this.props.onChangePage}
              onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }
}

export default withStyles(defaultFooterStyles, { name: 'CustomFooter' })(
  CustomFooter
);
