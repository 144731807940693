import React, { Component } from 'react';
import { Filter, AutocompleteInput, TextInput, DateInput } from 'react-admin';
import { getWidgetUsers } from '../DataProvider/WidgetUsers';

class UserFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      choices: {}
    };
  }

  async componentWillMount () {
    const data = await getWidgetUsers();
    let resp = [];
    resp = data.map(elem => ({ ...elem, name:elem.widget_id, id:elem.widget_id }));
    resp.length && await this.setState({ choices: resp });
  }

  render () {
    return (
      <Filter {...this.props}>
        <DateInput source='createdAt' label='Created At' />
        <AutocompleteInput label='Widget Id' source='widget_id' choices={this.state.choices} />
        <TextInput label='Search' source='q' alwaysOn />
      </Filter>
    );
  }
}

export default UserFilter;
