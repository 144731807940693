const momentime = require('moment-timezone');
const moment = require('moment');

export function localTimeAndDate({hour, minute}, {status}, {version}) {
    let appendDateAndTime ;
    const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (version !== undefined && version === 'FinNews') {
      return `sent`;
    }
    if (Array.isArray(hour)) {
      let date = moment().day();
      appendDateAndTime = hour[date];
      return !appendDateAndTime.length ? "Not scheduled": `${status}(at - ${appendDateAndTime})`; 
    }
    if (hour !== undefined && minute !== undefined) {
      let now = moment().format("YYYY-MM-DD");
      appendDateAndTime = `${now} ${hour}:${minute}`;
      let localDate = momentime(appendDateAndTime).tz(tzString).format('HH:MM');
      return `${status} (at - ${localDate})`;
    } 
    else {
      return `${status}`
    }  
};

  