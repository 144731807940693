import React, { useState, useEffect } from 'react';
import { SimpleForm, TextInput, Button, useNotify } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';

import './corporate.css';
import { getCorporateInfo, patchCorporateInfo } from '../../DataProvider/Corporate';
import { v4 as uuidv4 } from 'uuid';

const styles = {
    block: { display: 'block', marginRight: '1rem', width: '100%' },
    inlineBlock: { display: 'inline-block', width: '50%' },
    flexBlock: { display: 'flex', width: '100%' },
    contactContainer: { marginBottom: '1rem' },
    none: { display: 'none' }
};

const EditCorporateInfo = ({ classes, ...props }) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [metadata, setMetadata] = useState([]);
    const [message, setMessage] = useState('');
    const notify = useNotify();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = window.location.href;
                const parts = url.split('/');
                const lastPart = parts[parts.length - 1];
                const id = lastPart.split('%')[0];
                setId(id);
                const data = await getCorporateInfo(id);
                setName(data.name);
                if (data.metadata && data.metadata.contact) {
                    setMetadata(data.metadata.contact);
                }
            } catch (error) {
                console.log('[CorporateEdit][willmount] ======= ', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const [, idx, propName] = name.match(/metadata\[(\d+)\]\.(.+)/);
        const updatedMetadata = [...metadata];

        const isDuplicate = updatedMetadata.some((contact, i) => i !== parseInt(idx) && contact[propName] === value);
        if (isDuplicate) {
            setMessage('Duplicate user found');
            return;
        } else {
            setMessage('');
        }

        if (propName === 'phone') {
            const isValidPhone = /^\d{10}$/.test(value);
            if (!isValidPhone) {
                setMessage('Invalid phone number');
                return;
            } else {
                setMessage('');
            }
        }

        if (propName === 'email') {
            const isValidEmail = /\S+@\S+\.\S+/.test(value);
            if (!isValidEmail) {
                setMessage('Invalid email address');
                return;
            } else {
                setMessage('');
            }
        }

        updatedMetadata[idx][propName] = value;
        setMetadata(updatedMetadata);
    };

    const handleData = (e) => {
        const { name, value } = e.target;
        setName({
            [name]: value
        });
    };

    const handleAddContact = () => {
        setMetadata(prevMetadata => [
            ...prevMetadata,
            { fullname: '', role: '', email: '', phone: '' }
        ]);
    };

    const isEveryFieldEmpty = (obj) => {
        return Object.values(obj).every(value => !value);
    };

    const savingCorporateInfo = async () => {
        const emptyMetadata = [];
        const filteredMetadata = metadata.filter(obj => {
            const isEmpty = isEveryFieldEmpty(obj);
            if (isEmpty) {
                emptyMetadata.push(obj);
            }
            return !isEmpty;
        });
        const metadataVal = {
            metadata: {
                contact: filteredMetadata
            }
        };
        if (id !== '') {
            await patchCorporateInfo(id, metadataVal);
            setMetadata(filteredMetadata)
            if (emptyMetadata.length !== 0) {
                notify(`Contacts has been updated successfully without empty contact fields`, { type: 'success' })
            } else {
                notify('Contacts has been updated successfully',{ type: 'success' })

            }
        }
    };

    return (
        <SimpleForm formClassName={classes.inlineBlock} className='s-form' save={savingCorporateInfo}>
            <h3 className="custom-text">Company</h3>
            {name && <TextInput label='Corporate client name' source='name' formClassName={classes.block} className="custom-text-data" onChange={handleData} defaultValue={name} key={uuidv4()} disabled />}
            {metadata && !_.isEmpty(metadata) && metadata.map((contact, index) => (
                <div key={index} className={'contactContainer'}>
                    <h3 formClassName={classes.block} className="custom-text">Contact</h3>
                    <TextInput label='Full name' source={`metadata[${index}].fullname`} formClassName={classes.inlineBlock} className='input-fields' defaultValue={contact.fullname || ''} onChange={(e) => handleChange(index, e)} />
                    <TextInput label='Role at company' source={`metadata[${index}].role`} options={{ multiLine: true }} className='input-fields' formClassName={classes.inlineBlock} defaultValue={contact.role || ''} onChange={(e) => handleChange(index, e)} />
                    <TextInput label='Email' source={`metadata[${index}].email`} formClassName={classes.inlineBlock} className='input-fields' defaultValue={contact.email || ''} onChange={(e) => handleChange(index, e)} />
                    <TextInput label='Contact number' source={`metadata[${index}].phone`} options={{ multiLine: true }} className='input-fields' formClassName={classes.inlineBlock} defaultValue={contact.phone || ''} onChange={(e) => handleChange(index, e)} />
                </div>
            ))}
            {message.length !== 0 && <div className='error-div' style={{ width: '92%' }}>{message}</div>}
            <Button label="Add other contact" disabled={0} onClick={handleAddContact} className={(0) ? 'custom-btn' : 'enable-btn'} />
        </SimpleForm>
    );
}

export default withStyles(styles)(EditCorporateInfo);

