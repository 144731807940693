import React, { Component } from 'react';
import { Button, TextField, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

import { generateCoupon } from '../../DataProvider/Coupon';
const styles = theme => ({
  button: {
    marginTop: '20px'
  },
  root: {
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '25px'
  },
  paper: {
    padding: '48px',
    margin: '20px',
    width: 300,
    justifyContent: 'center',
    display: 'inline-block'
  },
  h1:{
    marginBottom: '20px'
  }
});

class Action extends Component {
  constructor () {
    super();
    this.state = {
      count : '',
      successMsg: ''
    };
  }
  handleOnChange = event => {
    event.preventDefault();
    const { value } = event.target;
    let count = value;
    this.setState({ count });
  };
  handleSubmit = async (id) => {
    let CouponGenerated = await generateCoupon(id, this.state.count);
    if (CouponGenerated) {
      let successMsg = `${CouponGenerated.length} coupons has been generated successfully.`;
      this.setState({ successMsg }, () => {
        document.getElementById('coupon').value = '';
      });
    }
  };
  render () {
    const { classes, id } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} >
          <Typography variant='title' component='h1' className={classes.h1}>
            Generate Coupon
          </Typography>
          <form onSubmit={() => this.handleSubmit(id)}>
            <TextField
              autoFocus
              required
              fullWidth
              id='coupon'
              type='number'
              InputProps={{ inputProps: { max: 80000 } }}
              placeholder='Max 80000 is allowed in one time.'
              onChange={this.handleOnChange}
            />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              Generate
            </Button>
          </form>
        </Paper>
        {this.state.successMsg ? <Paper>
          <Alert onClose={() => this.setState({ successMsg : '' })} >{this.state.successMsg}</Alert>
        </Paper> : null}
      </div>);
  }
}
Action.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object
};
export default withStyles(styles)(Action);
