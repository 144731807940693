import React from 'react';
import { Tooltip } from '@material-ui/core';

const ColumnHeaderWithTooltip = ({ label, description }) => (
  <Tooltip title={description} arrow>
    <div>{label}</div>
  </Tooltip>
);

export default ColumnHeaderWithTooltip;
