import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getHistory } from '../../DataProvider/History';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class UserHistory extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      rows: [],
      limit: 10,
      skip: 0,
      loading: true
    };
  }

  async componentWillMount () {
    let versionid = '';
    try {
      if (_.has(this.props.users, 'data')) {
        versionid = this.props.users.data[0].version_id;
      }
    } catch (error) {
      console.log('[ERROR] ========== versionid', error);
    }
    const params = {
      limit: this.state.limit,
      skip: this.state.skip,
      version_id : versionid
    };
    if (_.has(this.props, 'userId')) {
      params.user_id = this.props.userId;
    } else if (_.has(this.props, 'event')) {
      params.event = this.props.event;
    }
    const history = await getHistory(params);
    let rows = [];
    if (_.has(history, 'data') && history.data.length > 0) {
      _.map(history.data, event => {
        event.metadata = JSON.stringify(event.metadata);
        rows.push(event);
      });
    }
    this.setState({
      rows: rows,
      skip: history.skip,
      limit: history.limit,
      loading: false
    });
  }

  renderMetadata = (value, meta, update) => {
    value = JSON.parse(value);
    if (value.length > 0) {
      return (
        value.map((data, key) => {
          return Object.keys(data)[0] === 'newline' ? <hr /> : (!_.has(data, 'Version id') && !_.has(data, 'Corporate id')) ? <p><b>{Object.keys(data)[0]}:&emsp;&emsp;</b>{Object.values(data)[0]}</p> : null;
        })
      );
    } else {
      return null;
    }
  }

  render () {
    const columns = [
      {
        name: 'date',
        label: 'Date',
        options: {
          sort: true,
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          sort: true,
        }
      },
      {
        name: 'os_type',
        label: 'OS type',
      },
      {
        name: 'metadata',
        label: 'Metadata',
        selectableRows: 'none',
        options: {
          filter: true,
          sort: true,
          customBodyRender: this.renderMetadata
        }
      }
    ];
    if (_.has(this.props, 'event')) {
      columns.splice(2, 1);
    }
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <MUIDataTable
            title={''}
            data={this.state.rows || []}
            columns={columns || {}}
            options={{
              textLabels: {
                body: {
                  noMatch: this.state.loading
                    ? <div style={{ textAlign:'center', marginTop:'10px', marginLeft: '-100px' }}>
                      <CircularProgress size={60} thickness={4} />
                    </div>
                    : 'Sorry, there is no matching data to display',
                },
              },
              print:'false',
              selectableRows: 'none',
              filter: 'false'
            }}
          />
        </div>
      </Paper>
    );
  }
}

UserHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.string,
  event: PropTypes.string,
};

export default withStyles(styles)(UserHistory);
