/* eslint-disable indent */
import axios from 'axios';

export const purgeUserPermanently = (userID) => {
 const url = `${process.env.REACT_APP_API_BASE_URL}/users/${userID}`;
 const token = localStorage.getItem('token');
 console.log('url...', url);
 return axios
  .delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      cert: 'admin',
      platform: 'ops'
    },
    params: {
      action: 'purgeUser',
    }
})
  .then(response => {
    return response;
  })
  .catch(err => {
   console.log(err.message);
   return {};
  });
};
