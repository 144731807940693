export const provinceOptions = [
  { id:'AG', name:'AG' },
  { id:'AL', name:'AL' },
  { id:'AN', name:'AN' },
  { id:'AO', name:'AO' },
  { id:'AQ', name:'AQ' },
  { id:'AR', name:'AR' },
  { id:'AP', name:'AP' },
  { id:'AT', name:'AT' },
  { id:'AV', name:'AV' },
  { id:'BA', name:'BA' },
  { id:'BT', name:'BT' },
  { id:'BL', name:'BL' },
  { id:'BN', name:'BN' },
  { id:'BG', name:'BG' },
  { id:'BI', name:'BI' },
  { id:'BO', name:'BO' },
  { id:'BZ', name:'BZ' },
  { id:'BS', name:'BS' },
  { id:'BR', name:'BR' },
  { id:'CA', name:'CA' },
  { id:'CL', name:'CL' },
  { id:'CB', name:'CB' },
  { id:'CI', name:'CI' },
  { id:'CE', name:'CE' },
  { id:'CT', name:'CT' },
  { id:'CZ', name:'CZ' },
  { id:'CH', name:'CH' },
  { id:'CO', name:'CO' },
  { id:'CS', name:'CS' },
  { id:'CR', name:'CR' },
  { id:'KR', name:'KR' },
  { id:'CN', name:'CN' },
  { id:'EN', name:'EN' },
  { id:'FM', name:'FM' },
  { id:'FE', name:'FE' },
  { id:'FI', name:'FI' },
  { id:'FG', name:'FG' },
  { id:'FC', name:'FC' },
  { id:'FR', name:'FR' },
  { id:'GE', name:'GE' },
  { id:'GO', name:'GO' },
  { id:'GR', name:'GR' },
  { id:'IM', name:'IM' },
  { id:'IS', name:'IS' },
  { id:'SP', name:'SP' },
  { id:'LT', name:'LT' },
  { id:'LE', name:'LE' },
  { id:'LC', name:'LC' },
  { id:'LI', name:'LI' },
  { id:'LO', name:'LO' },
  { id:'LU', name:'LU' },
  { id:'MC', name:'MC' },
  { id:'MN', name:'MN' },
  { id:'MS', name:'MS' },
  { id:'MT', name:'MT' },
  { id:'VS', name:'VS' },
  { id:'ME', name:'ME' },
  { id:'MI', name:'MI' },
  { id:'MO', name:'MO' },
  { id:'MB', name:'MB' },
  { id:'NA', name:'NA' },
  { id:'NO', name:'NO' },
  { id:'NU', name:'NU' },
  { id:'OG', name:'OG' },
  { id:'OT', name:'OT' },
  { id:'OR', name:'OR' },
  { id:'PD', name:'PD' },
  { id:'PA', name:'PA' },
  { id:'PR', name:'PR' },
  { id:'PV', name:'PR' },
  { id:'PG', name:'PG' },
  { id:'PU', name:'PU' },
  { id:'PE', name:'PE' },
  { id:'PC', name:'PC' },
  { id:'PI', name:'PI' },
  { id:'PT', name:'PT' },
  { id:'PN', name:'PN' },
  { id:'PZ', name:'PZ' },
  { id:'PO', name:'PO' },
  { id:'RG', name:'RG' },
  { id:'RA', name:'RA' },
  { id:'RC', name:'RC' },
  { id:'RE', name:'RE' },
  { id:'RI', name:'RI' },
  { id:'RN', name:'RN' },
  { id:'RM', name:'RM' },
  { id:'RO', name:'RO' },
  { id:'SA', name:'SA' },
  { id:'SS', name:'SS' },
  { id:'SV', name:'SV' },
  { id:'SI', name:'SI' },
  { id:'SR', name:'SR' },
  { id:'SO', name:'SO' },
  { id:'TA', name:'TA' },
  { id:'TE', name:'TE' },
  { id:'TR', name:'TR' },
  { id:'TO', name:'TO' },
  { id:'TP', name:'TP' },
  { id:'TN', name:'TN' },
  { id:'TV', name:'TV' },
  { id:'TS', name:'TS' },
  { id:'UD', name:'UD' },
  { id:'VA', name:'VA' },
  { id:'VE', name:'VE' },
  { id:'VB', name:'VB' },
  { id:'VC', name:'VC' },
  { id:'VR', name:'VR' },
  { id:'VV', name:'VV' },
  { id:'VI', name:'VI' },
  { id:'VT', name:'VT' }
];
